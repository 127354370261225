import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as SfIcon } from "../images/starmemory-icon-V2.svg";
import "./css/LoaderMedium.css";

const LoaderMedium = ({ message }) => (
  <div className="LoaderMedium">
    <div>
      <SfIcon />
    </div>
    {message && <div>{message}</div>}
  </div>
);

LoaderMedium.defaultProps = {
  message: ""
};

LoaderMedium.propTypes = {
  message: PropTypes.string
};

export default LoaderMedium;
