import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import DisplayPicker from "../../DisplayPicker";
import DatePicker from "../../DatePicker";

const StreakControls = ({
  startDate,
  endDate,
  setDateRange,
  daysToDisplay,
  displayType,
  handleChangeDisplayType
}) => {
  const startDateBackClick = () => {
    const newStartDate = moment(startDate.subtract(1, "days"));
    const newEndDate = moment(endDate.subtract(1, "days"));

    setDateRange({ startDate: newStartDate, endDate: newEndDate });
  };

  const startDateForwardClick = () => {
    setDateRange({
      startDate: moment(startDate).add(1, "days"),
      endDate: moment(endDate).add(1, "days")
    });
  };

  const startDateDayChange = day => {
    const newEndDate = moment(endDate).date(day);
    const newStartDate = moment(newEndDate).subtract(daysToDisplay, "days");

    setDateRange({
      startDate: newStartDate,
      endDate: newEndDate
    });
  };

  const startDateMonthChange = month => {
    let newEndDate = moment(endDate).month(month);
    if (newEndDate > moment()) {
      newEndDate = moment();
    }
    const newStartDate = moment(newEndDate).subtract(daysToDisplay, "days");

    setDateRange({
      startDate: newStartDate,
      endDate: newEndDate
    });
  };

  const startDateYearChange = year => {
    const newEndDate = moment(endDate).year(year);
    const newStartDate = moment(newEndDate).subtract(daysToDisplay, "days");

    // TODO: check if the dates are in the future, if they are, move end date back to the present day...?
    setDateRange({
      startDate: newStartDate,
      endDate: newEndDate
    });
  };

  return (
    <div className="DisplayControls">
      <div className="DisplayControls__Type" role="group">
        <DisplayPicker
          displayType={displayType}
          handleChangeDisplayType={handleChangeDisplayType}
        />
      </div>
      <div className="DisplayControls__Custom">
        <DatePicker
          backClick={startDateBackClick}
          forwardClick={startDateForwardClick}
          onDayChange={startDateDayChange}
          onMonthChange={startDateMonthChange}
          onYearChange={startDateYearChange}
          date={endDate}
          label="End Date"
        />
      </div>
    </div>
  );
};

StreakControls.propTypes = {
  startDate: PropTypes.object.isRequired,
  endDate: PropTypes.object.isRequired,
  setDateRange: PropTypes.func.isRequired,
  daysToDisplay: PropTypes.number.isRequired,
  displayType: PropTypes.string.isRequired,
  handleChangeDisplayType: PropTypes.func.isRequired
};

export default StreakControls;
