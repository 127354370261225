import React from "react";
import PropTypes from "prop-types";
import { Droppable, Draggable } from "react-beautiful-dnd";
import EditableTracker from "./EditableTracker";
import "./css/EditableTrackerList.css";

const EditableTrackerList = ({
  categoryId,
  trackers,
  trackersSort,
  handleOnChangeTracker,
  handleConfirmDeleteTracker,
  openTrackerModal
}) => {
  if (trackersSort == null) trackersSort = [];

  const trackersList = trackersSort.map((trackerId, index) => {
    const tracker = trackers[trackerId];

    if (!tracker) return null;

    return (
      <Draggable
        draggableId={`tracker-${trackerId}`}
        index={index}
        key={trackerId}
      >
        {(provided, snapshot) => (
          <li ref={provided.innerRef} {...provided.draggableProps}>
            <EditableTracker
              dragHandleProps={provided.dragHandleProps}
              trackerId={trackerId}
              tracker={tracker}
              handleOnChangeTracker={handleOnChangeTracker}
              handleConfirmDeleteTracker={handleConfirmDeleteTracker}
              openTrackerModal={openTrackerModal}
            />
          </li>
        )}
      </Draggable>
    );
  });

  return (
    <Droppable droppableId={`cat-${categoryId}`} type="TRACKER">
      {(provided, snapshot) => (
        <ul
          ref={provided.innerRef}
          style={{ backgroundColor: snapshot.isDraggingOver ? "#0af" : "" }}
          {...provided.droppableProps}
          className="EditableTrackerList"
        >
          {trackersList}
          {provided.placeholder}
        </ul>
      )}
    </Droppable>
  );
};

EditableTrackerList.propTypes = {
  trackers: PropTypes.object
};

export default EditableTrackerList;
