import React from "react";
import PropTypes from "prop-types";
import {
  DISPLAY_DAY_TYPE_EMOJIS,
  DISPLAY_DAY_TYPE_TINYSQUARES,
  DISPLAY_DAY_TYPE_SQUARES,
  DISPLAY_DAY_TYPE_POINTS,
  DISPLAY_DAY_TYPE_TOTAL
} from "../../../lib/constants.js";
import DisplayPicker from "../../DisplayPicker";
import DatePicker from "../../DatePicker";

const TimelineControls = ({
  handleChangeDisplayType,
  endDate,
  displayType,
  displayMode,
  changeDisplayMode,
  setDateRange
}) => {
  const startDateBackClick = () => {
    setDateRange(endDate.subtract(1, "month"));
  };

  const startDateForwardClick = () => {
    setDateRange(endDate.add(1, "month"));
  };

  const startDateMonthChange = month => {
    setDateRange(endDate.month(month));
  };

  const startDateYearChange = year => {
    setDateRange(endDate.year(year));
  };

  return (
    <div className="DisplayControls">
      <div className="DisplayControls__Type" role="group">
        <DisplayPicker
          displayType={displayType}
          handleChangeDisplayType={handleChangeDisplayType}
        />
      </div>
      <div className="DisplayControls__Custom">
        <div className="DisplayControls__Mode" role="group">
          <label className="DisplayControls__Label" htmlFor="display-mode">
            Mode
          </label>
          <select
            id="display-mode"
            value={displayMode}
            onChange={e => changeDisplayMode(e.target.value)}
          >
            <option value={DISPLAY_DAY_TYPE_EMOJIS}>Emojis</option>
            <option value={DISPLAY_DAY_TYPE_SQUARES}>Squares</option>
            <option value={DISPLAY_DAY_TYPE_TINYSQUARES}>Tiny Squares</option>
            <option value={DISPLAY_DAY_TYPE_POINTS}>Score</option>
            <option value={DISPLAY_DAY_TYPE_TOTAL}>Total</option>
          </select>
        </div>
        <DatePicker
          backClick={startDateBackClick}
          forwardClick={startDateForwardClick}
          onMonthChange={startDateMonthChange}
          onYearChange={startDateYearChange}
          date={endDate}
          hideDate={true}
          label="End Date"
        />
      </div>
    </div>
  );
};

TimelineControls.propTypes = {
  year: PropTypes.number,
  month: PropTypes.number,
  onYearChange: PropTypes.func,
  onMonthChange: PropTypes.func
};

export default TimelineControls;
