import React from "react";
import Modal from "react-modal";
import modalStyles from "../../../lib/modalStyles";
import "./css/VerifyEmailModal.css";

Modal.setAppElement("#root");

const VerifyEmailModal = ({ modalIsOpen, closeModal }) => {
  const onClose = () => {
    closeModal();
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={onClose}
      contentLabel="Verify Email Address Modal"
      style={modalStyles}
    >
      <div className="VerifyEmailModal modal-content">
        <div className="modal-header">
          <h2>Verification Email Sent</h2>
          <div>
            <button className="modal-close" onClick={closeModal}>
              Close
            </button>
          </div>
        </div>
        <p>
          We have sent an email with a verification link to your email address
          which should arrive shortly with a link to follow to finalize your
          email address verification.
        </p>
      </div>
    </Modal>
  );
};

export default VerifyEmailModal;
