// Third Party Libraries
import React, { Component } from "react";
import moment from "moment";
import { firestore, fireauth } from "./lib/firebase";
import arrayMove from "array-move";
import { DragDropContext } from "react-beautiful-dnd";
import { debounce } from "debounce";
import { getEmojiDataFromNative } from "emoji-mart";
import emojiData from "emoji-mart/data/all.json";

// Utils etc.
import {
  MOBILE_BREAK,
  VIEW_DETAIL_DAY,
  VIEW_DATA_DISPLAY,
  DISPLAY_TYPE_STREAK,
  DISPLAY_TYPES,
  NEW_TRACKER_LABEL,
  DEFAULT_DATASET,
  DATE_FORMAT_SHORT
} from "./lib/constants.js";

// React components
import Header from "./components/Header";
import Tabs from "./components/Tabs";
import Sidebar from "./components/sidebar/Sidebar";
import SignedOut from "./components/pages/SignedOut";
import Loading from "./components/pages/Loading";
import Display from "./components/displays/Display";

// CSS
import "./css/App.css";
import "./css/Animations.css";
import "./css/Modals.css";

const defaultState = {
  awaitingInitAuth: true,
  awaitingUserSetup: true,
  user: null,
  userData: null,
  selectedDate: moment(),
  displayType: DISPLAY_TYPE_STREAK,
  filteredTrackers: [],
  trackers: {},
  trackersSort: {},
  categories: {},
  categoriesSort: [],
  selectedDataSet: DEFAULT_DATASET,
  loadingDataset: true,
  usedEmojisData: [],
  addingTrackers: [],
  addingCategory: false,
  dragging: false
};

class App extends Component {
  state = {
    ...defaultState,
    compact: true,
    displayOpen: false,
    sidebarOpen: true,
    selectedTab: VIEW_DETAIL_DAY,
    sidebarCollapsed: false
  };

  _userDocRef = null;
  _trackersRef = null;
  _categoriesRef = null;
  _filteredTrackersId = null;
  _displayTypeId = null;
  _isMounted = false;

  // React Lifecycle Methods
  componentDidMount() {
    this._isMounted = true;
    this.initAuthListener();
    this.onWindowResize();
    this.onWindowResizeDebounced = debounce(this.onWindowResize, 200);
    window.addEventListener("resize", this.onWindowResizeDebounced);
  }

  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener("resize", this.onWindowResizeDebounced);
    if (this.unregisterAuthObserver) this.unregisterAuthObserver(); // Firebase
    if (this.unSubUserDocOnSnapShot) this.unSubUserDocOnSnapShot();
    if (this.unSubDataSetOnSnapShot) this.unSubDataSetOnSnapShot();
    if (this.unSubTrackersOnSnapShot) this.unSubTrackersOnSnapShot();
    if (this.unSubCategoriesOnSnapShot) this.unSubCategoriesOnSnapShot();
  }

  //
  initAuthListener = () => {
    this.unregisterAuthObserver = fireauth.onAuthStateChanged(user => {
      if (user && user.uid) {
        this._userDocRef = firestore.collection("users").doc(user.uid);

        this._userDocRef.get().then(userDoc => {
          const userDocData = userDoc.data();
          let newUserData = { lastLogin: moment().toDate() };

          if (!userDoc.exists || !userDocData.nextTrackerId) {
            newUserData.nextTrackerId = 1;
          }

          this._userDocRef.set(newUserData, { merge: true });
        });

        this.getUserData();
        this.getUserCategoriesAndTrackers();

        this._filteredTrackersId = `${user.uid}:fts`;
        let filteredTrackers = JSON.parse(
          localStorage.getItem(this._filteredTrackersId)
        );
        if (filteredTrackers === null) {
          filteredTrackers = [];
          localStorage.setItem(
            this._filteredTrackersId,
            JSON.stringify(filteredTrackers)
          );
        }

        this._displayTypeId = `${user.uid}:dt`;
        let displayType = localStorage.getItem(this._displayTypeId);

        if (displayType === null) {
          displayType = DISPLAY_TYPE_STREAK;
          localStorage.setItem(this._displayTypeId, displayType);
        }

        if (this._isMounted) {
          this.setState({
            user,
            filteredTrackers,
            displayType,
            awaitingInitAuth: false,
            selectedTab: VIEW_DETAIL_DAY
          });
        }
      } else {
        if (this.unSubUserDocOnSnapShot) this.unSubUserDocOnSnapShot();
        if (this.unSubDataSetOnSnapShot) this.unSubDataSetOnSnapShot();
        if (this.unSubTrackersOnSnapShot) this.unSubTrackersOnSnapShot();
        if (this.unSubCategoriesOnSnapShot) this.unSubCategoriesOnSnapShot();

        if (this._isMounted) {
          this.setState({ ...defaultState, awaitingInitAuth: false });
        }
      }
    });
  };

  initNewUserCategoriesAndTrackers = dataSetRef => {
    let newCategoriesSort = [];
    let newTrackersSort = {};

    dataSetRef
      .collection("categories")
      .add({
        label: "Good Habits"
      })
      .then(categoryDocRef => {
        newCategoriesSort.push(categoryDocRef.id);
        newTrackersSort[categoryDocRef.id] = [];

        firestore
          .runTransaction(transaction => {
            return transaction.get(this._userDocRef).then(userDoc => {
              if (!userDoc.exists) {
                throw new Error("User document doesn't exist");
              }

              let nextTrackerId = userDoc.data().nextTrackerId;
              let newTrackers = { ...this.state.trackers };
              const newTracker = {
                categoryId: categoryDocRef.id,
                label: "Tracked Stuff",
                colour: "#4fbbff",
                emoji: "⭐",
                points: 1
              };

              transaction.update(this._userDocRef, {
                nextTrackerId: nextTrackerId + 1
              });

              transaction.set(
                dataSetRef.collection("trackers").doc(nextTrackerId.toString()),
                newTracker
              );

              newTrackers[nextTrackerId.toString()] = { ...newTracker };
              newTrackersSort[categoryDocRef.id].push(nextTrackerId.toString());

              return {
                newTrackerId: nextTrackerId.toString(),
                newTrackers: newTrackers
              };
            });
          })
          .then(result => {
            const newItems = [result.newTrackerId];

            dataSetRef
              .collection("entries")
              .doc(moment().format(DATE_FORMAT_SHORT))
              .set({
                days: {
                  [moment(this.props.date).format("DD")]: {
                    items: newItems,
                    notes: "Created a Starmemory account 🙂"
                  }
                }
              });

            dataSetRef
              .set(
                {
                  categoriesSort: newCategoriesSort,
                  trackersSort: newTrackersSort
                },
                { merge: true }
              )
              .then(() => {
                this.setState({
                  categoriesSort: newCategoriesSort,
                  trackersSort: newTrackersSort,
                  trackers: result.newTrackers,
                  awaitingUserSetup: false
                });
              });
          });
      });
  };

  // Firebase Data Binding
  getUserData() {
    if (this.unSubUserDocOnSnapShot) this.unSubUserDocOnSnapShot();

    this.unSubUserDocOnSnapShot = this._userDocRef.onSnapshot(doc => {
      const userData = doc.data();

      if (userData) {
        this.setState({
          userData
        });
      }
    });
  }

  fixCategorySort(categoriesSort, categories) {
    let newCategoriesSort = [...categoriesSort];
    const categoryIds = Object.keys(categories);

    newCategoriesSort.forEach((categoryId, i) => {
      if (!categoryIds.includes(categoryId)) {
        newCategoriesSort.splice(i, 1);
      }
    });

    categoryIds.forEach(categoryId => {
      if (!categoriesSort.includes(categoryId)) {
        newCategoriesSort.push(categoryId);
      }
    });

    return newCategoriesSort;
  }

  fixTrackersSort(trackersSort, trackers, categories) {
    let newTrackersSort = { ...trackersSort };

    const categoryIds = Object.keys(categories);
    // loop through trackersSort keys (category IDs)
    //   remove any categories that don't exist in the categories object
    Object.keys(newTrackersSort).forEach(categoryId => {
      if (!categoryIds.includes(categoryId)) {
        delete newTrackersSort[categoryId];
      }
    });

    // loop through categories object keys (IDs)
    //    add any that don't exist in trackersSort object
    categoryIds.forEach(categoryId => {
      if (newTrackersSort[categoryId] == null) {
        newTrackersSort[categoryId] = [];
      }
    });

    // loop through each tracker
    //   if tracker doesn't exist in category array in trackersSort object
    //     add tracker to category array in trackersSort object
    Object.keys(trackers).forEach(trackerId => {
      const categoryId = trackers[trackerId].categoryId;

      if (
        newTrackersSort[categoryId] &&
        !newTrackersSort[categoryId].includes(trackerId)
      ) {
        newTrackersSort[categoryId].push(trackerId);
      }
    });

    // loop through all trackers in category arrays in trackersSort object
    //   if tracker doesn't exist in trackers object or if the tracker is in the wrong category
    //      remove tracker from it's category array in trackersSort object
    const trackerIds = Object.keys(trackers);
    for (let [categoryId, trackerList] of Object.entries(newTrackersSort)) {
      trackerList.forEach((trackerId, i) => {
        if (
          !trackerIds.includes(trackerId) ||
          trackers[trackerId].categoryId !== categoryId
        ) {
          newTrackersSort[categoryId].splice(i, 1);
        }
      });
    }

    return newTrackersSort;
  }

  getUserCategoriesAndTrackers() {
    this.dataSetRef = this._userDocRef
      .collection("dataSets")
      .doc(this.state.selectedDataSet);

    this.dataSetRef.get().then(dataSetDoc => {
      let dataSetData = {};

      if (dataSetDoc.exists) {
        dataSetData = dataSetDoc.data();
      } else {
        const newDataSetLabel =
          this.state.selectedDataSet === DEFAULT_DATASET ? "Default" : "New";
        dataSetDoc.ref.set({
          label: newDataSetLabel,
          dateAdded: new Date()
        });

        // Adding the default dataSet, so we'll add some default data for new users as well
        if (this.state.selectedDataSet === DEFAULT_DATASET) {
          this.initNewUserCategoriesAndTrackers(dataSetDoc.ref);
        } else {
          this.setState({ awaitingUserSetup: false });
        }
      }

      // CATEGORIES
      this._categoriesRef = dataSetDoc.ref.collection("categories");
      this._categoriesRef
        .get()
        .then(snapShot => {
          let categories = {};
          snapShot.forEach(doc => {
            categories[doc.id] = doc.data();
          });

          // TRACKERS
          this._trackersRef = dataSetDoc.ref.collection("trackers");
          this._trackersRef.get().then(snapShot => {
            let trackers = {};
            snapShot.forEach(doc => {
              trackers[doc.id] = doc.data();
            });

            let categoriesSort = dataSetData.categoriesSort;
            if (categoriesSort == null) categoriesSort = [];

            let fixedcategoriesSort = this.fixCategorySort(
              categoriesSort,
              categories
            );

            let trackersSort = dataSetData.trackersSort;
            if (trackersSort == null) trackersSort = {};

            let fixedTrackersSort = this.fixTrackersSort(
              trackersSort,
              trackers,
              categories
            );

            dataSetDoc.ref
              .set(
                {
                  categoriesSort: fixedcategoriesSort,
                  trackersSort: fixedTrackersSort
                },
                { merge: true }
              )
              .then(() => {
                this.setState({
                  trackers: trackers,
                  categoriesSort: fixedcategoriesSort,
                  trackersSort: fixedTrackersSort,
                  categories: categories,
                  loadingDataset: false
                });
              });
          });
        })
        .then(() => {
          if (this.unSubDataSetOnSnapShot) {
            this.unSubDataSetOnSnapShot();
          }

          this.unSubDataSetOnSnapShot = this.dataSetRef.onSnapshot(snapShot => {
            const data = snapShot.exists
              ? snapShot.data()
              : { categoriesSort: [], trackersSort: {} };

            this.setState({
              categoriesSort: data.categoriesSort,
              trackersSort: data.trackersSort
            });
          });

          if (this.unSubCategoriesOnSnapShot) {
            this.unSubCategoriesOnSnapShot();
          }

          this.unSubCategoriesOnSnapShot = this.dataSetRef
            .collection("categories")
            .onSnapshot(snapShot => {
              let categories = {};

              snapShot.forEach(doc => {
                categories[doc.id] = doc.data();
              });

              this.setState({
                categories
              });
            });

          if (this.unSubTrackersOnSnapShot) {
            this.unSubTrackersOnSnapShot();
          }

          this.unSubTrackersOnSnapShot = this.dataSetRef
            .collection("trackers")
            .onSnapshot(snapShot => {
              const source = snapShot.metadata.hasPendingWrites
                ? "Local"
                : "Server";

              let trackers = {};
              snapShot.forEach(doc => {
                trackers[doc.id] = doc.data();
              });

              if (source === "Server") {
                this.setState({
                  trackers: trackers
                });
              }

              const usedEmojis = Object.keys(trackers).map(trackerId => {
                return trackers[trackerId].emoji;
              });

              // TODO: when changing a tracker (or adding a new one...)
              // we should add the emoji there so we can skip this for local pending writes
              const uniqueUsedEmojis = Array.from(new Set(usedEmojis));
              let usedEmojisData = {};
              uniqueUsedEmojis.forEach(emoji => {
                const emojiInfo = getEmojiDataFromNative(
                  emoji,
                  "all",
                  emojiData
                );

                usedEmojisData[emojiInfo.native] = emojiInfo.id;
              });

              this.setState({ usedEmojisData });
            });
        });
    });
  }

  //
  onWindowResize = () => {
    this.setState(prevState => {
      let newState = {};

      if (window.innerWidth < MOBILE_BREAK && !prevState.compact) {
        newState = {
          compact: true,
          displayOpen: false,
          sidebarOpen: true,
          selectedTab: VIEW_DETAIL_DAY,
          sidebarCollapsed: false
        };
      } else if (window.innerWidth >= MOBILE_BREAK && prevState.compact) {
        newState = {
          compact: false,
          displayOpen: true,
          sidebarOpen: true,
          selectedTab: VIEW_DETAIL_DAY
        };
      }

      return newState;
    });
  };

  // App State Handlers
  handleDataSetChange = selectedDataSet => {
    this.setState(
      {
        trackers: {},
        categories: {},
        selectedDataSet: selectedDataSet,
        loadingDataset: true
      },
      () => {
        this.getUserCategoriesAndTrackers();
      }
    );
  };

  setSelectedDay = date => {
    this.setState({
      selectedDate: date
    });
  };

  handleChangeDisplayType = displayType => {
    this.setState(
      {
        displayType
      },
      () => {
        localStorage.setItem(this._displayTypeId, displayType);
      }
    );
  };

  handleTabClick = tab => {
    this.setState(prevState => {
      let displayOpen = true;
      let sidebarOpen = true;
      let displayType = prevState.displayType;

      if (prevState.compact) {
        if (tab === VIEW_DATA_DISPLAY || DISPLAY_TYPES.includes(tab)) {
          displayOpen = true;
          sidebarOpen = false;
        } else {
          displayOpen = false;
          sidebarOpen = true;
        }

        if (DISPLAY_TYPES.includes(tab)) {
          displayType = tab;
        }
      }

      return {
        displayOpen: displayOpen,
        sidebarOpen: sidebarOpen,
        selectedTab: tab,
        displayType: displayType
      };
    });
  };

  handleCollapseSidebar = () => {
    this.setState(prevState => {
      return { sidebarCollapsed: !prevState.sidebarCollapsed };
    });
  };

  // Categories
  handleChangeCategoryLabel = (label, categoryId) => {
    this._categoriesRef.doc(categoryId).set({ label: label }, { merge: true });
  };

  handleConfirmDeleteCategory = categoryId => {
    if (
      categoryId &&
      this.state.trackersSort[categoryId] &&
      window.confirm(
        "All trackers under this category will also be deleted. Are you sure you want to delete this category?"
      )
    ) {
      let batch = firestore.batch();
      let trackersSort = { ...this.state.trackersSort };
      let categoriesSort = [...this.state.categoriesSort];

      trackersSort[categoryId].forEach(trackerId => {
        let trackerRef = this._trackersRef.doc(trackerId);
        batch.delete(trackerRef);
      });

      delete trackersSort[categoryId];

      const categoryRef = this._categoriesRef.doc(categoryId);
      batch.delete(categoryRef);

      const sortIndex = categoriesSort.indexOf(categoryId);
      categoriesSort.splice(sortIndex, 1);

      batch.commit().then(() => {
        this.dataSetRef.update({
          categoriesSort: categoriesSort,
          trackersSort: trackersSort
        });
      });
    }
  };

  handleAddNewCategory = () => {
    const oldCategoriesSort = [...this.state.categoriesSort];
    const oldTrackersSort = { ...this.state.trackersSort };
    let newCategoriesSort = [...this.state.categoriesSort];
    let newTrackersSort = { ...this.state.trackersSort };

    this.setState({ addingCategory: true });

    this._categoriesRef
      .add({
        label: "New Category"
      })
      .then(categoryDocRef => {
        newCategoriesSort.push(categoryDocRef.id);
        newTrackersSort[categoryDocRef.id] = [];

        this.dataSetRef.set(
          {
            categoriesSort: newCategoriesSort,
            trackersSort: newTrackersSort
          },
          { merge: true }
        );

        this.setState({
          addingCategory: false,
          categoriesSort: newCategoriesSort,
          trackersSort: newTrackersSort
        });
      })
      .catch(() => {
        this.setState({
          addingCategory: false,
          categoriesSort: oldCategoriesSort,
          trackersSort: oldTrackersSort
        });
      });
  };

  // Trackers
  updateTrackerDetails = (trackerId, details) => {
    const oldTrackers = { ...this.state.trackers };
    let newTrackers = { ...this.state.trackers };

    newTrackers[trackerId] = { ...newTrackers[trackerId], ...details };
    this.setState({ trackers: newTrackers });

    this._trackersRef
      .doc(trackerId)
      .set({ ...details }, { merge: true })
      .catch(() => {
        this.setState({ trackers: oldTrackers });
      });
  };

  handleOnChangeTracker = (trackerId, key, value) => {
    const oldTrackers = { ...this.state.trackers };
    let newTrackers = { ...this.state.trackers };

    newTrackers[trackerId][key] = value;
    this.setState({ trackers: newTrackers });

    const newData = {};
    newData[key] = value;

    this._trackersRef
      .doc(trackerId)
      .set(newData, { merge: true })
      .catch(() => {
        this.setState({ trackers: oldTrackers });
      });
  };

  handleAddNewTracker = categoryId => {
    const oldTrackers = { ...this.state.trackers };
    const oldAddingTrackers = [...this.state.addingTrackers];

    this.setState(prevState => {
      let newAddingTrackers = [...prevState.addingTrackers];
      newAddingTrackers.push(categoryId);
      return { addingTrackers: newAddingTrackers };
    });

    firestore
      .runTransaction(transaction => {
        return transaction.get(this._userDocRef).then(userDoc => {
          if (!userDoc.exists) {
            throw new Error("User document doesn't exist");
          }

          let nextTrackerId = userDoc.data().nextTrackerId;
          let newTrackers = { ...this.state.trackers };
          const newTracker = {
            categoryId: categoryId,
            label: NEW_TRACKER_LABEL,
            colour: "#36f",
            emoji: "🚀",
            points: 0
          };

          transaction.update(this._userDocRef, {
            nextTrackerId: nextTrackerId + 1
          });

          transaction.set(
            this._trackersRef.doc(nextTrackerId.toString()),
            newTracker
          );

          newTrackers[nextTrackerId.toString()] = { ...newTracker };
          this.setState({ trackers: newTrackers });

          return nextTrackerId;
        });
      })
      .then(nextTrackerId => {
        let newTrackersSort = { ...this.state.trackersSort };
        let oldTrackersSort = { ...this.state.trackersSort };
        newTrackersSort[categoryId].push(nextTrackerId.toString());

        this.setState({
          addingTrackers: oldAddingTrackers,
          trackersSort: newTrackersSort
        });

        this.dataSetRef
          .set(
            {
              trackersSort: newTrackersSort
            },
            { merge: true }
          )
          .catch(() => {
            this.setState({
              addingTrackers: oldAddingTrackers,
              trackersSort: oldTrackersSort
            });
          });
      })
      .catch(() => {
        this.setState({
          addingTrackers: oldAddingTrackers,
          trackers: oldTrackers
        });
      });
  };

  handleConfirmDeleteTracker = trackerId => {
    if (
      trackerId &&
      window.confirm("Are you sure you want to delete this tracker?")
    ) {
      let newTrackersSort = { ...this.state.trackersSort };
      let oldTrackersSort = { ...this.state.trackersSort };

      for (let [categoryId, trackerList] of Object.entries(newTrackersSort)) {
        trackerList.forEach((id, i) => {
          if (trackerId === id) {
            newTrackersSort[categoryId].splice(i, 1);
          }
        });
      }

      this.setState({
        trackersSort: newTrackersSort
      });

      this._trackersRef
        .doc(trackerId)
        .delete()
        .then(() => {
          this.dataSetRef.update({
            trackersSort: newTrackersSort
          });
        })
        .catch(() => {
          this.setState({
            trackersSort: oldTrackersSort
          });
        });
    }
  };

  // Filtering
  toggleCategoryFiltered = categoryId => {
    let filteredTrackers;

    this.setState(
      prevState => {
        let trackers = { ...prevState.trackers };
        filteredTrackers = [...prevState.filteredTrackers];

        Object.keys(trackers).forEach(trackerId => {
          if (trackers[trackerId].categoryId === categoryId) {
            const index = filteredTrackers.indexOf(trackerId);

            if (index >= 0) {
              filteredTrackers.splice(index, 1);
            } else {
              filteredTrackers.push(trackerId);
            }
          }
        });

        return {
          filteredTrackers: filteredTrackers
        };
      },
      () => {
        localStorage.setItem(
          this._filteredTrackersId,
          JSON.stringify(filteredTrackers)
        );
      }
    );
  };

  toggleTrackerFiltered = trackerId => {
    let filteredTrackers;

    this.setState(
      prevState => {
        filteredTrackers = [...prevState.filteredTrackers];
        const index = filteredTrackers.indexOf(trackerId);

        if (index >= 0) {
          filteredTrackers.splice(index, 1);
        } else {
          filteredTrackers.push(trackerId);
        }

        return {
          filteredTrackers
        };
      },
      () => {
        localStorage.setItem(
          this._filteredTrackersId,
          JSON.stringify(filteredTrackers)
        );
      }
    );
  };

  // Drag & Drop
  moveTrackerSameCategory = (categoryId, oldIndex, newIndex) => {
    const oldTrackersSort = { ...this.state.trackersSort };
    let newTrackersSort = { ...this.state.trackersSort };

    arrayMove.mutate(newTrackersSort[categoryId], oldIndex, newIndex);

    this.setState({ trackersSort: newTrackersSort });

    this._userDocRef
      .collection("dataSets")
      .doc(this.state.selectedDataSet)
      .set({ trackersSort: newTrackersSort }, { merge: true })
      .catch(() => {
        this.setState({ trackersSort: oldTrackersSort });
      });
  };

  moveTrackerOtherCategory = (
    trackerId,
    oldCategoryId,
    newCategoryId,
    oldIndex,
    newIndex
  ) => {
    const oldTrackersSort = { ...this.state.trackersSort };
    let newTrackersSort = { ...this.state.trackersSort };

    newTrackersSort[oldCategoryId].splice(oldIndex, 1);
    newTrackersSort[newCategoryId].splice(newIndex, 0, trackerId);

    // TODO: update tracker's category in state too?
    this.setState({ trackersSort: newTrackersSort });

    this._trackersRef
      .doc(trackerId)
      .set({ categoryId: newCategoryId }, { merge: true })
      .then(() => {
        this._userDocRef
          .collection("dataSets")
          .doc(this.state.selectedDataSet)
          .set({ trackersSort: newTrackersSort }, { merge: true })
          .catch(() => {
            this.setState({ trackersSort: oldTrackersSort });
          });
      });
  };

  moveCategory = (oldIndex, newIndex) => {
    let newCategoriesSort = [...this.state.categoriesSort];
    const oldCategoriesSort = [...this.state.categoriesSort];

    arrayMove.mutate(newCategoriesSort, oldIndex, newIndex);

    this.setState({ categoriesSort: newCategoriesSort });

    this.dataSetRef
      .set({ categoriesSort: newCategoriesSort }, { merge: true })
      .catch(() => {
        this.setState({ categoriesSort: oldCategoriesSort });
      });
  };

  onDragStart = () => {
    this.setState({ dragging: true });
  };

  onDragEnd = (result, provided) => {
    this.setState({ dragging: false });

    if (result.destination && result.source) {
      if (result.type === "TRACKER") {
        const droppableIdParts = result.destination.droppableId.split("-");

        if (result.destination.droppableId === result.source.droppableId) {
          this.moveTrackerSameCategory(
            droppableIdParts[1],
            result.source.index,
            result.destination.index
          );
        } else {
          const sourcedroppableIdParts = result.source.droppableId.split("-");
          const draggableIdParts = result.draggableId.split("-");

          this.moveTrackerOtherCategory(
            draggableIdParts[1],
            sourcedroppableIdParts[1],
            droppableIdParts[1],
            result.source.index,
            result.destination.index
          );
        }
      } else if (result.type === "CATEGORY") {
        this.moveCategory(result.source.index, result.destination.index);
      }
    }
  };

  render() {
    if (this.state.awaitingInitAuth) {
      return <Loading />;
    }

    if (!this.state.user) {
      return <SignedOut />;
    }

    if (!this.state.userData && !this.state.awaitingUserSetup) {
      return <Loading />;
    }

    return (
      <DragDropContext
        onDragStart={this.onDragStart}
        onDragEnd={this.onDragEnd}
      >
        <div
          className={[
            "App",
            this.state.dragging ? "dragging" : "not-dragging"
          ].join(" ")}
        >
          <Header
            userDocRef={this._userDocRef}
            selectedDataSet={this.state.selectedDataSet}
            handleDataSetChange={this.handleDataSetChange}
          />
          {this.state.compact && (
            <Tabs
              showDisplayButtons={true}
              selectedTab={this.state.selectedTab}
              handleTabClick={this.handleTabClick}
            />
          )}
          <main className="App-content">
            {this.state.displayOpen && (
              <Display
                handleChangeDisplayType={this.handleChangeDisplayType}
                compact={this.state.compact}
                sidebarCollapsed={this.state.sidebarCollapsed}
                loadingDataset={this.state.loadingDataset}
                trackers={this.state.trackers}
                filteredTrackers={this.state.filteredTrackers}
                selectedDate={this.state.selectedDate}
                onDayClick={this.setSelectedDay}
                displayType={this.state.displayType}
                user={this.state.user}
                userDocRef={this._userDocRef}
                selectedDataSet={this.state.selectedDataSet}
                categoriesSort={this.state.categoriesSort}
                trackersSort={this.state.trackersSort}
                categories={this.state.categories}
              />
            )}
            {this.state.sidebarOpen && (
              <Sidebar
                sidebarCollapsed={this.state.sidebarCollapsed}
                handleCollapseSidebar={this.handleCollapseSidebar}
                handleTabClick={this.handleTabClick}
                selectedTab={this.state.selectedTab}
                compact={this.state.compact}
                loadingDataset={this.state.loadingDataset}
                usedEmojisData={this.state.usedEmojisData}
                user={this.state.user}
                userData={this.state.userData}
                trackers={this.state.trackers}
                trackersSort={this.state.trackersSort}
                categories={this.state.categories}
                categoriesSort={this.state.categoriesSort}
                selectedDate={this.state.selectedDate}
                setSelectedDay={this.setSelectedDay}
                //
                handleDataSetChange={this.handleDataSetChange}
                selectedDataSet={this.state.selectedDataSet}
                //
                toggleCategoryFiltered={this.toggleCategoryFiltered}
                toggleTrackerFiltered={this.toggleTrackerFiltered}
                filteredTrackers={this.state.filteredTrackers}
                //
                handleAddNewTracker={this.handleAddNewTracker}
                handleOnChangeTracker={this.handleOnChangeTracker}
                updateTrackerDetails={this.updateTrackerDetails}
                handleConfirmDeleteTracker={this.handleConfirmDeleteTracker}
                addingTrackers={this.state.addingTrackers}
                //
                handleAddNewCategory={this.handleAddNewCategory}
                handleChangeCategoryLabel={this.handleChangeCategoryLabel}
                handleConfirmDeleteCategory={this.handleConfirmDeleteCategory}
                addingCategory={this.state.addingCategory}
              />
            )}
          </main>
        </div>
      </DragDropContext>
    );
  }
}

export default App;
