import React from "react";
import { trackerColours } from "../lib/colours.js";
import "./css/ColourPicker.css";

const ColourPicker = ({ colour, onSelectColour }) => (
  <div className="ColourPicker">
    <ul>
      {trackerColours.map(hex => (
        <li key={hex} className={colour === hex ? "selected" : ""}>
          <button
            className="sm-outliner"
            title={hex}
            style={{ backgroundColor: hex }}
            onClick={() => onSelectColour(hex)}
          ></button>
        </li>
      ))}
    </ul>
  </div>
);

export default ColourPicker;
