import React, { useState } from "react";
import Modal from "react-modal";
import Octicon, { Plus, Dash, Star } from "@primer/octicons-react";
import { Picker } from "emoji-mart";
import ColourPicker from "../../ColourPicker.js";
import modalStyles from "../../../lib/modalStyles";
import "./css/TrackerModal.css";
import "emoji-mart/css/emoji-mart.css";

Modal.setAppElement("#root");

const TrackerModal = ({
  modalIsOpen,
  closeTrackerModal,
  trackerId,
  tracker,
  updateTrackerDetails,
  usedEmojisData
}) => {
  const emojiData = usedEmojisData[tracker.emoji];
  const usedEmojiIds = Object.keys(usedEmojisData).map(key => {
    return usedEmojisData[key];
  });

  const [colour, setColour] = useState(tracker.colour);
  const [emoji, setEmoji] = useState(tracker.emoji);
  const [emojiId, setEmojiId] = useState(emojiData.id);
  const [points, setPoints] = useState(tracker.points);

  const onClose = () => {
    if (
      colour !== tracker.colour ||
      emoji !== tracker.emoji ||
      points !== tracker.points
    ) {
      updateTrackerDetails(trackerId, { colour, emoji, points });
    }

    closeTrackerModal();
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={onClose}
      contentLabel={`Edit ${tracker.label} Details Modal`}
      style={modalStyles}
    >
      {trackerId && (
        <div className="TrackerModal modal-content">
          <div className="modal-header">
            <h2>Edit Details</h2>
            <div>
              <button className="modal-close" onClick={onClose}>
                Close
              </button>
            </div>
          </div>
          <ul>
            <li>
              <label>Preview</label>
              <div className="TrackerModal__Preview">
                <div className="sm-emoji" style={{ backgroundColor: colour }}>
                  <span>{emoji}</span>
                </div>
                <div className="TrackerModal__Label">{tracker.label}</div>
              </div>
            </li>
            <li>
              <label>Emoji</label>
              <div className="TrackerModal__EmojiPicker">
                <Picker
                  style={{ width: "252px" }}
                  emojiSize={18}
                  native={true}
                  title=""
                  perLine={8}
                  recent={usedEmojiIds}
                  emoji={emojiId}
                  onSelect={emoji => {
                    setEmojiId(emoji.id);
                    setEmoji(emoji.native);
                  }}
                />
              </div>
            </li>
            <li>
              <label>Colour</label>
              <div className="TrackerModal__ColourPicker">
                <ColourPicker
                  colour={colour}
                  onSelectColour={hex => {
                    setColour(hex);
                  }}
                />
              </div>
            </li>
            <li>
              <label>Points</label>
              <div className="TrackerModal__Points">
                <button
                  className="sm-outliner"
                  title="Increase Points"
                  onClick={() => setPoints(points - 1)}
                >
                  <Octicon icon={Dash} />
                </button>
                <span>
                  <Octicon icon={Star} verticalAlign="top" />
                  {points}
                </span>
                <button
                  className="sm-outliner"
                  title="Decrease Points"
                  onClick={() => setPoints(points + 1)}
                >
                  <Octicon icon={Plus} />
                </button>
              </div>
            </li>
          </ul>
        </div>
      )}
    </Modal>
  );
};

export default TrackerModal;
