import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import Octicon, { ArrowLeft, ArrowRight } from "@primer/octicons-react";

import {
  MonthOptions,
  DayOptions,
  YearOptions
} from "./DateControlsOptions.js";

const DatePicker = ({
  label,
  date,
  backClick,
  forwardClick,
  onDayChange,
  onMonthChange,
  onYearChange,
  hideDate
}) => {
  return (
    <div role="group" aria-labelledby="DisplayControls__Date">
      <div className="DisplayControls__Label" id="DisplayControls__Date">
        {label}
      </div>
      {backClick && (
        <button type="button" onClick={backClick}>
          <Octicon
            icon={ArrowLeft}
            verticalAlign="middle"
            ariaLabel="Move back one day"
          />
        </button>
      )}
      <select
        aria-label="Month"
        id="month"
        value={date.month()}
        title="Month"
        onChange={e => {
          onMonthChange(e.target.value);
        }}
      >
        <MonthOptions endDate={date} />
      </select>
      {!hideDate && (
        <select
          aria-label="Day"
          id="day"
          value={date.date()}
          title="Day"
          onChange={e => {
            onDayChange(e.target.value);
          }}
        >
          <DayOptions endDate={date} disableFuture={true} />
        </select>
      )}
      <select
        aria-label="Year"
        id="year"
        value={date.year()}
        title="Year"
        onChange={e => {
          onYearChange(e.target.value);
        }}
      >
        <YearOptions />
      </select>
      {forwardClick && (
        <button
          type="button"
          onClick={forwardClick}
          disabled={moment(date).add(1, "day") >= moment()}
        >
          <Octicon
            icon={ArrowRight}
            verticalAlign="middle"
            ariaLabel="Move forward one day"
          />
        </button>
      )}
    </div>
  );
};

DatePicker.defaultProps = {
  hideDate: false
};

DatePicker.propTypes = {
  label: PropTypes.string,
  date: PropTypes.object,
  backClick: PropTypes.func,
  forwardClick: PropTypes.func,
  onDayChange: PropTypes.func,
  onMonthChange: PropTypes.func,
  onYearChange: PropTypes.func,
  hideDate: PropTypes.bool
};

export default DatePicker;
