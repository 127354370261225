import React from "react";
import PropTypes from "prop-types";

import {
  DISPLAY_TYPE_TIMELINE,
  DISPLAY_TYPE_STREAK,
  DISPLAY_TYPE_CHART
} from "../lib/constants.js";

const DisplayPicker = ({ displayType, handleChangeDisplayType }) => (
  <div className="DisplayPicker">
    <label htmlFor="display-select">Display</label>
    <select
      id="display-select"
      value={displayType}
      onChange={e => handleChangeDisplayType(e.target.value)}
    >
      <option value={DISPLAY_TYPE_STREAK}>Streak</option>
      <option value={DISPLAY_TYPE_TIMELINE}>Timeline</option>
      <option value={DISPLAY_TYPE_CHART}>Chart</option>
    </select>
  </div>
);

DisplayPicker.propTypes = {
  displayType: PropTypes.string,
  handleChangeDisplayType: PropTypes.func.isRequired
};

export default DisplayPicker;
