import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import Timeline from "./Timeline/Timeline";
import Streak from "./Streak/Streak";
import Chart from "./Chart/Chart";
import Loader from "../Loader";
import "./Display.css";
import { DATE_FORMAT_SHORT } from "../../lib/constants.js";

const Displays = {
  Timeline,
  Streak,
  Chart
};

class Display extends Component {
  _isMounted = false;
  _snapshotListeners = {};

  state = {
    startDate: moment().subtract(2, "weeks"),
    endDate: moment(),
    entries: {}
  };

  componentDidMount() {
    this._isMounted = true;
    this.entriesRef = this.props.userDocRef
      .collection("dataSets")
      .doc(this.props.selectedDataSet)
      .collection("entries");
    this.getUserEntries();
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.detachListeners();
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedDataSet !== prevProps.selectedDataSet) {
      this.entriesRef = this.props.userDocRef
        .collection("dataSets")
        .doc(this.props.selectedDataSet)
        .collection("entries");
      this.detachListeners();
      this.getUserEntries();
    }
  }

  getUserEntries() {
    let startMonth = moment(this.state.startDate).startOf("month");
    let endMonth = moment(this.state.endDate).startOf("month");

    while (startMonth.diff(endMonth) <= 0) {
      if (
        !Object.keys(this._snapshotListeners).includes(
          startMonth.format("YYYY-MM")
        )
      ) {
        this.createListener(moment(startMonth));
      }

      startMonth.add(1, "months");
    }
  }

  createListener = date => {
    if (!this.entriesRef) return null;

    const shortDate = date.format(DATE_FORMAT_SHORT);

    let unSubEntriesOnSnapshot = this.entriesRef
      .doc(date.format(DATE_FORMAT_SHORT))
      .onSnapshot(doc => {
        if (doc.exists) {
          const data = doc.data();
          let entries = {};

          if (data.days) {
            for (const day in data.days) {
              const dayData = data.days[day];
              entries[`${shortDate}-${day}`] = dayData;
            }
          }

          if (this._isMounted) {
            this.setState(prevState => {
              const mergedEntries = { ...prevState.entries, ...entries };
              return { entries: mergedEntries };
            });
          }
        }
      });

    this._snapshotListeners[
      date.startOf("month").format(DATE_FORMAT_SHORT)
    ] = unSubEntriesOnSnapshot;

    // console.log(
    //   "Current Display Snapshots: ",
    //   Object.keys(this._snapshotListeners)
    // );
  };

  detachListeners = () => {
    Object.keys(this._snapshotListeners).forEach(dateKey => {
      this._snapshotListeners[dateKey]();
      delete this._snapshotListeners[dateKey];
    });
  };

  setDateRange = ({ startDate, endDate }) => {
    let newDateRange = {};

    if (startDate) {
      if (startDate.isAfter(endDate)) {
        newDateRange.startDate = moment(endDate);
      } else {
        newDateRange.startDate = startDate;
      }
    }

    if (endDate) {
      newDateRange.endDate = endDate;
    }

    this.setState(newDateRange, () => {
      this.getUserEntries();
    });
  };

  render() {
    const {
      handleChangeDisplayType,
      sidebarCollapsed,
      trackers,
      filteredTrackers,
      selectedDate,
      onDayClick,
      displayType,
      user,
      userDocRef,
      selectedDataSet,
      categoriesSort,
      trackersSort,
      categories,
      compact
    } = this.props;

    const Display = Displays[displayType];

    if (!Display) return null;

    return (
      <div
        className={[
          "Display",
          sidebarCollapsed ? "Display--sidebar-collapsed" : "",
          compact ? "Display--compact" : ""
        ].join(" ")}
      >
        {this.props.loadingDataset ? (
          <Loader alt="Loading Data" />
        ) : (
          <Display
            handleChangeDisplayType={handleChangeDisplayType}
            displayType={displayType}
            compact={compact}
            sidebarCollapsed={sidebarCollapsed}
            setDateRange={this.setDateRange}
            entries={this.state.entries}
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            trackers={trackers}
            filteredTrackers={filteredTrackers}
            selectedDate={selectedDate}
            onDayClick={onDayClick}
            user={user}
            userDocRef={userDocRef}
            selectedDataSet={selectedDataSet}
            categoriesSort={categoriesSort}
            trackersSort={trackersSort}
            categories={categories}
          />
        )}
      </div>
    );
  }
}

Display.propTypes = {
  handleChangeDisplayType: PropTypes.func.isRequired,
  compact: PropTypes.bool.isRequired,
  sidebarCollapsed: PropTypes.bool.isRequired,
  loadingDataset: PropTypes.bool.isRequired,
  trackers: PropTypes.object,
  filteredTrackers: PropTypes.array,
  selectedDate: PropTypes.object,
  onDayClick: PropTypes.func.isRequired,
  displayType: PropTypes.string.isRequired,
  userDocRef: PropTypes.object,
  selectedDataSet: PropTypes.string,
  categoriesSort: PropTypes.array,
  trackersSort: PropTypes.object,
  categories: PropTypes.object
};

export default Display;
