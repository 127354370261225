import React from "react";
import "./css/TrackerList.css";
import PropTypes from "prop-types";
import Tracker from "./Tracker";

const TrackerList = ({
  items,
  trackers,
  trackersSort,
  filteredTrackers,
  toggleTrackerFiltered,
  addItemToEntry,
  subtractItemFromEntry
}) => {
  if (trackersSort == null) trackersSort = [];

  const trackersList = trackersSort.map(trackerId => {
    const itemCount = items
      ? items.reduce((acc, item) => {
          return acc + (item === trackerId ? 1 : 0);
        }, 0)
      : 0;
    const trackerData = trackers[trackerId];

    return (
      <li key={trackerId}>
        <Tracker
          trackerId={trackerId}
          filtered={filteredTrackers.includes(trackerId)}
          toggleTrackerFiltered={toggleTrackerFiltered}
          trackerData={trackerData}
          itemCount={itemCount}
          addItemToEntry={addItemToEntry}
          subtractItemFromEntry={subtractItemFromEntry}
        />
      </li>
    );
  });

  return <ul className="TrackerList">{trackersList}</ul>;
};

TrackerList.propTypes = {
  categoryId: PropTypes.string
};

export default TrackerList;
