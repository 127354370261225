import React from "react";
import PropTypes from "prop-types";

const DayPoints = ({ items, trackers, filteredTrackers }) => {
  if (items == null || trackers == null) {
    return null;
  }

  const total = items.reduce((accumulator, trackerId) => {
    if (!trackers[trackerId]) return accumulator;

    if (
      filteredTrackers.includes(trackerId) ||
      !trackers[trackerId].points ||
      isNaN(trackers[trackerId].points)
    ) {
      return accumulator;
    } else {
      return accumulator + parseInt(trackers[trackerId].points, 10);
    }
  }, 0);

  if (total === 0) return null;

  let pointSize = "Number--small";
  if (total > 30) {
    pointSize = "Number--huge";
  } else if (total > 20) {
    pointSize = "Number--large";
  } else if (total > 10) {
    pointSize = "Number--medium";
  }

  return (
    <div
      className={[
        "Number",
        "Points",
        pointSize,
        total < 0 ? "Number--negative" : "Number--positive"
      ].join(" ")}
    >
      {total}
    </div>
  );
};

DayPoints.propTypes = {
  items: PropTypes.array,
  filteredTrackers: PropTypes.array,
  trackers: PropTypes.object
};

export default DayPoints;
