import React, { useState } from "react";
import Modal from "react-modal";
import { Formik, Form, Field, ErrorMessage } from "formik";
import LoaderMedium from "../../LoaderMedium";
import modalStyles from "../../../lib/modalStyles";
import { firestore } from "../../../lib/firebase";
import "./css/SendFeedbackModal.css";

Modal.setAppElement("#root");

const ChangePasswordModal = ({ user, modalIsOpen, closeModal }) => {
  const [error, setError] = useState(null);
  const [sending, setSending] = useState(false);
  const [feedbackSent, setFeedbackSent] = useState(false);

  const onClose = () => {
    setError(null);
    setSending(false);
    setFeedbackSent(false);
    closeModal();
  };

  const sendFeedback = message => {
    setSending(true);

    firestore
      .collection("feedback")
      .add({
        user: user.uid,
        userEmail: user.email,
        message: message,
        dateAdded: new Date()
      })
      .then(() => {
        setSending(false);
        setFeedbackSent(true);
      })
      .catch(e => {
        setSending(false);
        setError(e.message);
      });
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={onClose}
      contentLabel="Send Feedback Modal"
      style={modalStyles}
    >
      <div className="SendFeedbackModal modal-content modal-form">
        <div className="modal-header">
          <h2>Feedback</h2>
          <div>
            <button className="modal-close" onClick={onClose}>
              Close
            </button>
          </div>
        </div>
        {!feedbackSent && !sending && (
          <Formik
            initialValues={{
              message: ""
            }}
            validate={values => {
              const errors = {};

              if (!values.message) {
                errors.message = "Required";
              }

              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(false);
              sendFeedback(values.message);
            }}
          >
            {({ errors, touched, isSubmitting }) => (
              <>
                <Form>
                  <ul>
                    <li
                      className={
                        errors.message && touched.message ? "field-error" : ""
                      }
                    >
                      <label htmlFor="message">Message</label>
                      <Field as="textarea" name="message" />
                      <ErrorMessage
                        name="message"
                        className="SendFeedbackModal__Error modal-form__error"
                        component="div"
                      />
                    </li>
                    <li>
                      <button type="submit" disabled={isSubmitting}>
                        Send Feedback
                      </button>
                    </li>
                  </ul>
                </Form>
                {error && (
                  <div className="SendFeedbackModal__Error modal-form__error">
                    <p>{error}</p>
                  </div>
                )}
              </>
            )}
          </Formik>
        )}
        {sending && (
          <div className="SendFeedbackModal__Sending">
            <LoaderMedium message="Sending…" />
          </div>
        )}
        {feedbackSent && <p>Your feedback has been sent. Thank you so much!</p>}
      </div>
    </Modal>
  );
};

export default ChangePasswordModal;
