import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { ResponsiveLine } from "@nivo/line";
import { useDebouncedCallback } from "use-debounce";
import moment from "moment";
import {
  DATE_FORMAT,
  WEEK_THRESH,
  MONTH_THRESH,
  LINE_TYPE_ALL,
  LINE_TYPE_CATEGORIES,
  LINE_TYPE_TRACKERS,
  ROLLUP_TYPE_DAY,
  ROLLUP_TYPE_WEEK,
  ROLLUP_TYPE_MONTH
} from "../../../lib/constants.js";
import { hexToRgb } from "../../../lib/utils.js";
import { chartColours } from "../../../lib/colours.js";
import ChartControls from "./ChartControls";
import "./Chart.css";

const lineTypes = [LINE_TYPE_ALL, LINE_TYPE_CATEGORIES, LINE_TYPE_TRACKERS];

const theme = {
  background: "var(--space)",
  axis: {
    domain: {
      line: {
        strokeWidth: 0,
        stroke: "#526271"
      }
    },
    ticks: {
      line: {
        strokeWidth: 1,
        stroke: "#fff"
      },
      text: {
        fill: "#fff",
        fontSize: 11
      }
    },
    legend: {
      text: {
        fill: "#fff",
        fontSize: 12,
        fontWeight: 500
      }
    }
  },
  grid: {
    line: {
      stroke: "#444"
    }
  },
  legends: {
    text: {
      fontSize: 12,
      fontVariant: "small-caps",
      fill: "#fff"
    }
  },
  tooltip: {
    container: {
      fontSize: "13px",
      background: "var(--ship)",
      color: "var(--space)"
    }
  },
  // labels: {
  //   text: {
  //     fill: "#ddd",
  //     fontSize: 12,
  //     fontWeight: 500
  //   }
  // },
  dots: {
    text: {
      fill: "#bbb",
      fontSize: 12
    }
  }
};

const xFormatter = rollupType => {
  if (rollupType === ROLLUP_TYPE_DAY) {
    return format => {
      const date = moment(format, DATE_FORMAT);

      if (date.date() === 1) {
        return date.format("MMM. D");
      } else if (date.dayOfYear() === 1) {
        return date.format("YYYY");
      } else {
        return date.format("D");
      }
    };
  } else if (rollupType === ROLLUP_TYPE_WEEK) {
    return format => {
      const [start, end] = format.split("|");
      const dateStart = moment(start, DATE_FORMAT);
      const dateEnd = end ? moment(end, DATE_FORMAT) : dateStart;

      if (dateStart.date() < 7) {
        return `${dateStart.format("MMM. D")} - ${dateEnd.format("D")}`;
      } else {
        return `${dateStart.format("D")} - ${dateEnd.format("D")}`;
      }
    };
  } else {
    return format => {
      const [start] = format.split("|");
      const dateStart = moment(start, DATE_FORMAT);

      return dateStart.month() === 0
        ? `${dateStart.format("YYYY MMM.")}`
        : `${dateStart.format("MMM.")}`;
    };
  }
};

const tooltipFormatter = (rollupType, byScore, lineType) => {
  return d => {
    const label = byScore ? "Score" : "Total";
    let dateString = "";

    if (rollupType === ROLLUP_TYPE_DAY) {
      dateString = moment(d.point.data.x, DATE_FORMAT).format("MMMM Do, YYYY");
    } else {
      const [start, end] = d.point.data.x.split("|");
      const startDate = moment(start, DATE_FORMAT);
      const endDate = moment(end, DATE_FORMAT);
      if (startDate.year() === endDate.year()) {
        if (startDate.month() === endDate.month()) {
          dateString =
            moment(start, DATE_FORMAT).format("MMMM Do") +
            " - " +
            moment(end, DATE_FORMAT).format("Do, YYYY");
        } else {
          dateString =
            moment(start, DATE_FORMAT).format("MMMM Do") +
            " - " +
            moment(end, DATE_FORMAT).format("MMMM Do, YYYY");
        }
      } else {
        dateString =
          moment(start, DATE_FORMAT).format("MMMM Do, YYYY") +
          " - " +
          moment(end, DATE_FORMAT).format("MMMM Do, YYYY");
      }
    }

    return (
      <div
        className="ChartDisplayTooltip"
        style={{
          borderColor: d.point.serieColor
        }}
      >
        {lineType !== LINE_TYPE_ALL && (
          <div
            className="ChartDisplayTooltip__Label"
            style={{ borderBottomColor: d.point.serieColor }}
          >
            {d.point.serieId}
          </div>
        )}
        <div className="ChartDisplayTooltip__Date">{dateString}</div>
        <div className="ChartDisplayTooltip__Data">
          {label}: {d.point.data.yFormatted}
        </div>
      </div>
    );
  };
};

const MyResponsiveLine = ({
  rollupType,
  byScore,
  lineType,
  data,
  setHighlightedItem,
  compact
}) => (
  <ResponsiveLine
    data={data}
    margin={{
      top: 10,
      right: !compact && lineType !== LINE_TYPE_ALL ? 200 : 20,
      bottom: 20,
      left: 60
    }}
    xScale={{ type: "point" }}
    yScale={{
      type: "linear",
      min: "auto",
      max: "auto",
      stacked: false,
      reverse: false
    }}
    axisTop={null}
    axisRight={null}
    axisBottom={{
      format: xFormatter(rollupType),
      orient: "bottom",
      tickSize: 0
    }}
    axisLeft={{
      orient: "left",
      tickSize: 0,
      legend: byScore ? "Score" : "Total",
      legendOffset: -40,
      legendPosition: "middle"
    }}
    colors={item => item.color}
    pointSize={8}
    pointLabel="y"
    pointLabelYOffset={-12}
    useMesh={true}
    theme={theme}
    tooltip={tooltipFormatter(rollupType, byScore, lineType)}
    legends={
      !compact && lineType !== LINE_TYPE_ALL
        ? [
            {
              anchor: "top-right",
              direction: "column",
              justify: false,
              translateX: 200,
              translateY: 0,
              itemsSpacing: 0,
              itemDirection: "left-to-right",
              itemWidth: 185,
              itemHeight: 20,
              itemOpacity: 1,
              symbolSize: 10,
              symbolShape: "square",
              itemTextColor: "#fff",
              onMouseEnter: d => {
                setHighlightedItem(d.label);
              },
              onMouseLeave: d => {
                setHighlightedItem(null);
              }
            }
          ]
        : []
    }
  />
);

const getTrackerDataByDay = (
  trackerId,
  startDate,
  endDate,
  entries,
  byScore,
  trackerPoints
) => {
  let data = [];

  for (
    var i = 0, date = moment(startDate);
    date.isSameOrBefore(endDate);
    date.add(1, "d"), i++
  ) {
    const entryKey = date.format(DATE_FORMAT);
    const entry = entries[entryKey] ? entries[entryKey] : null;
    let y = 0;

    if (entry && entry.items) {
      const filteredEntries = entry.items.filter(itemId => {
        return itemId === trackerId;
      });

      if (byScore) {
        y = filteredEntries.reduce(acc => {
          return acc + trackerPoints;
        }, 0);
      } else {
        y = filteredEntries.length;
      }
    }

    data.push({ x: entryKey, y: y });
  }

  return data;
};

const getTrackerDataByWeek = (
  trackerId,
  startDate,
  endDate,
  entries,
  byScore,
  trackerPoints
) => {
  let data = [];
  let date = moment(startDate);

  while (date.isSameOrBefore(endDate)) {
    const weekEnd = moment(date)
      .add(1, "weeks")
      .subtract(1, "days");
    let weekDay = moment(date);
    let weekBean = {
      x: `${date.format(DATE_FORMAT)}|${weekEnd.format(DATE_FORMAT)}`,
      y: 0
    };

    while (weekDay.isBefore(weekEnd)) {
      const entryKey = weekDay.format(DATE_FORMAT);
      const entry = entries[entryKey] ? entries[entryKey] : null;
      let y = 0;

      if (entry && entry.items) {
        const filteredEntries = entry.items.filter(itemId => {
          return itemId === trackerId;
        });

        if (byScore) {
          y = filteredEntries.reduce(acc => {
            return acc + trackerPoints;
          }, 0);
        } else {
          y = filteredEntries.length;
        }
      }

      weekBean.y += y;
      weekDay.add(1, "days");
    }

    data.push(weekBean);
    date.add(1, "weeks");
  }

  return data;
};

const getTrackerDataByMonth = (
  trackerId,
  startDate,
  endDate,
  entries,
  byScore,
  trackerPoints
) => {
  let data = [];
  let date = moment(startDate);

  while (date.isSameOrBefore(endDate)) {
    const monthEnd = moment(date).add(1, "month");
    let monthDay = moment(date);
    let monthBean = {
      x: `${date.format(DATE_FORMAT)}|${monthEnd.format(DATE_FORMAT)}`,
      y: 0
    };

    while (monthDay.isBefore(monthEnd)) {
      const entryKey = monthDay.format(DATE_FORMAT);
      const entry = entries[entryKey] ? entries[entryKey] : null;
      let y = 0;

      if (entry && entry.items) {
        const filteredEntries = entry.items.filter(itemId => {
          return itemId === trackerId;
        });

        if (byScore) {
          y = filteredEntries.reduce(acc => {
            return acc + trackerPoints;
          }, 0);
        } else {
          y = filteredEntries.length;
        }
      }

      monthBean.y += y;
      monthDay.add(1, "days");
    }

    data.push(monthBean);
    date.add(1, "month");
  }

  return data;
};

const getCategoryDataByDay = (
  trackers,
  trackerIds,
  filteredTrackers,
  startDate,
  endDate,
  entries,
  byScore
) => {
  let data = {};
  let hasNonFilteredTrackers = false;

  trackerIds.forEach(trackerId => {
    if (trackers[trackerId] && !filteredTrackers.includes(trackerId)) {
      hasNonFilteredTrackers = true;
      const trackerPoints = trackers[trackerId].points
        ? parseInt(trackers[trackerId].points, 10)
        : 0;

      for (
        var i = 0, date = moment(startDate);
        date.isSameOrBefore(endDate);
        date.add(1, "d"), i++
      ) {
        const entryKey = date.format(DATE_FORMAT);
        const entry = entries[entryKey] ? entries[entryKey] : null;
        let y = 0;

        if (entry && entry.items) {
          const filteredEntries = entry.items.filter(itemId => {
            return itemId === trackerId;
          });

          if (byScore) {
            y = filteredEntries.reduce(acc => {
              return acc + trackerPoints;
            }, 0);
          } else {
            y = filteredEntries.length;
          }
        }

        data[entryKey] = data[entryKey] ? data[entryKey] + y : y;
      }
    }
  });

  return hasNonFilteredTrackers
    ? Object.keys(data).map(entryKey => ({
        x: entryKey,
        y: data[entryKey]
      }))
    : null;
};

const getCategoryDataByWeek = (
  trackers,
  trackerIds,
  filteredTrackers,
  startDate,
  endDate,
  entries,
  byScore
) => {
  let data = {};
  let hasNonFilteredTrackers = false;

  trackerIds.forEach(trackerId => {
    let date = moment(startDate);
    if (trackers[trackerId] && !filteredTrackers.includes(trackerId)) {
      hasNonFilteredTrackers = true;
      const trackerPoints = trackers[trackerId].points
        ? parseInt(trackers[trackerId].points, 10)
        : 0;

      while (date.isSameOrBefore(endDate)) {
        const weekEnd = moment(date)
          .add(1, "weeks")
          .subtract(1, "days");
        let weekDay = moment(date);
        const weekKey = `${date.format(DATE_FORMAT)}|${weekEnd.format(
          DATE_FORMAT
        )}`;

        while (weekDay.isBefore(weekEnd)) {
          const entryKey = weekDay.format(DATE_FORMAT);
          const entry = entries[entryKey] ? entries[entryKey] : null;
          let y = 0;

          if (entry && entry.items) {
            const filteredEntries = entry.items.filter(itemId => {
              return itemId === trackerId;
            });

            if (byScore) {
              y += filteredEntries.reduce(acc => {
                return acc + trackerPoints;
              }, 0);
            } else {
              y += filteredEntries.length;
            }
          }

          data[weekKey] = data[weekKey] ? data[weekKey] + y : y;
          weekDay.add(1, "days");
        }

        date.add(1, "weeks");
      }
    }
  });

  return hasNonFilteredTrackers
    ? Object.keys(data).map(entryKey => ({
        x: entryKey,
        y: data[entryKey]
      }))
    : null;
};

const getCategoryDataByMonth = (
  trackers,
  trackerIds,
  filteredTrackers,
  startDate,
  endDate,
  entries,
  byScore
) => {
  let data = {};
  let hasNonFilteredTrackers = false;

  trackerIds.forEach(trackerId => {
    let date = moment(startDate);
    if (trackers[trackerId] && !filteredTrackers.includes(trackerId)) {
      hasNonFilteredTrackers = true;
      const trackerPoints = trackers[trackerId].points
        ? parseInt(trackers[trackerId].points, 10)
        : 0;

      while (date.isBefore(endDate)) {
        const monthEnd = moment(date).add(1, "months");
        let monthDay = moment(date);
        const monthKey = `${date.format(DATE_FORMAT)}|${monthEnd.format(
          DATE_FORMAT
        )}`;

        while (monthDay.isBefore(monthEnd)) {
          const entryKey = monthDay.format(DATE_FORMAT);
          const entry = entries[entryKey] ? entries[entryKey] : null;
          let y = 0;

          if (entry && entry.items) {
            const filteredEntries = entry.items.filter(itemId => {
              return itemId === trackerId;
            });

            if (byScore) {
              y += filteredEntries.reduce(acc => {
                return acc + trackerPoints;
              }, 0);
            } else {
              y += filteredEntries.length;
            }
          }

          data[monthKey] = data[monthKey] ? data[monthKey] + y : y;
          monthDay.add(1, "days");
        }

        date.add(1, "months");
      }
    }
  });

  return hasNonFilteredTrackers
    ? Object.keys(data).map(entryKey => ({
        x: entryKey,
        y: data[entryKey]
      }))
    : null;
};

const getAllDataByDay = (
  trackers,
  filteredTrackers,
  startDate,
  endDate,
  entries,
  byScore
) => {
  let data = [];

  for (
    var i = 0, date = moment(startDate);
    date.isSameOrBefore(endDate);
    date.add(1, "d"), i++
  ) {
    const entryKey = date.format(DATE_FORMAT);
    const entry = entries[entryKey] ? entries[entryKey] : null;
    let y = 0;

    if (entry && entry.items) {
      entry.items.forEach(item => {
        if (trackers[item] && !filteredTrackers.includes(item)) {
          if (byScore) {
            y += trackers[item].points;
          } else {
            y += 1;
          }
        }
      });
    }

    data.push({ x: entryKey, y: y });
  }

  return data;
};

const getAllDataByWeek = (
  trackers,
  filteredTrackers,
  startDate,
  endDate,
  entries,
  byScore
) => {
  let data = [];
  let date = moment(startDate);

  while (date.isSameOrBefore(endDate)) {
    const weekEnd = moment(date)
      .add(1, "weeks")
      .subtract(1, "days");
    const weekKey = `${date.format(DATE_FORMAT)}|${weekEnd.format(
      DATE_FORMAT
    )}`;
    let weekDay = moment(date);
    let y = 0;

    while (weekDay.isBefore(weekEnd)) {
      const entryKey = weekDay.format(DATE_FORMAT);
      const entry = entries[entryKey] ? entries[entryKey] : null;

      if (entry && entry.items) {
        y += entry.items.reduce((acc, item) => {
          if (trackers[item] && !filteredTrackers.includes(item)) {
            if (byScore) {
              return acc + trackers[item].points;
            } else {
              return acc + 1;
            }
          }
          return acc;
        }, 0);
      }

      weekDay.add(1, "days");
    }

    data.push({
      x: weekKey,
      y: y
    });

    date.add(1, "weeks");
  }

  return data;
};

const getAllDataByMonth = (
  trackers,
  filteredTrackers,
  startDate,
  endDate,
  entries,
  byScore
) => {
  let data = [];
  let date = moment(startDate);

  while (date.isSameOrBefore(endDate)) {
    const monthEnd = moment(date).add(1, "months");
    const monthKey = `${date.format(DATE_FORMAT)}|${monthEnd.format(
      DATE_FORMAT
    )}`;
    let monthDay = moment(date);
    let y = 0;

    while (monthDay.isBefore(monthEnd)) {
      const entryKey = monthDay.format(DATE_FORMAT);
      const entry = entries[entryKey] ? entries[entryKey] : null;

      if (entry && entry.items) {
        y += entry.items.reduce((acc, item) => {
          if (trackers[item] && !filteredTrackers.includes(item)) {
            if (byScore) {
              return acc + trackers[item].points;
            } else {
              return acc + 1;
            }
          }
          return acc;
        }, 0);
      }

      monthDay.add(1, "days");
    }

    data.push({
      x: monthKey,
      y: y
    });

    date.add(1, "months");
  }

  return data;
};

const getTrackerData = (
  rollupType,
  entries,
  startDate,
  endDate,
  trackers,
  filteredTrackers,
  trackersSort,
  byScore,
  highlightedItem
) => {
  let data = [];
  let usedLabels = [];

  Object.keys(trackersSort).forEach(categoryId => {
    trackersSort[categoryId].forEach(trackerId => {
      if (trackers[trackerId] && !filteredTrackers.includes(trackerId)) {
        let label = `${trackers[trackerId].emoji} ${trackers[trackerId].label}`;
        if (usedLabels.includes(trackers[trackerId].label)) {
          label = `${trackers[trackerId].emoji} ${trackers[trackerId].label} (${trackerId})`;
        } else {
          usedLabels.push(trackers[trackerId].label);
        }

        let colour = trackers[trackerId].colour;

        if (highlightedItem && highlightedItem !== label) {
          const rgb = hexToRgb(colour);
          colour = `rgba(${rgb.r},${rgb.g},${rgb.b}, 0.3)`;
        }

        const trackerPoints = trackers[trackerId].points
          ? parseInt(trackers[trackerId].points, 10)
          : 0;

        let trackerData = [];
        if (rollupType === ROLLUP_TYPE_DAY) {
          trackerData = getTrackerDataByDay(
            trackerId,
            startDate,
            endDate,
            entries,
            byScore,
            trackerPoints
          );
        } else if (rollupType === ROLLUP_TYPE_WEEK) {
          trackerData = getTrackerDataByWeek(
            trackerId,
            startDate,
            endDate,
            entries,
            byScore,
            trackerPoints
          );
        } else {
          trackerData = getTrackerDataByMonth(
            trackerId,
            startDate,
            endDate,
            entries,
            byScore,
            trackerPoints
          );
        }

        data.push({
          id: label,
          color: colour,
          data: trackerData
        });
      }
    });
  });

  return data;
};

const getCategoryData = (
  rollupType,
  entries,
  startDate,
  endDate,
  trackers,
  filteredTrackers,
  trackersSort,
  categories,
  categoriesSort,
  byScore,
  highlightedItem
) => {
  let data = [];
  let usedLabels = [];
  let duplicateLabelFixer = 1;
  let presetColourIndex = 0;
  let usedColours = new Set();

  categoriesSort.forEach(categoryId => {
    let categoryData = [];
    let colour = chartColours[presetColourIndex];
    let label = categories[categoryId].label;

    if (usedColours.has(colour)) {
      presetColourIndex++;

      if (presetColourIndex >= chartColours.length) {
        presetColourIndex = 0;
      }

      colour = chartColours[presetColourIndex];
    }

    usedColours.add(colour);

    if (highlightedItem && highlightedItem !== label) {
      const rgb = hexToRgb(colour);
      colour = `rgba(${rgb.r},${rgb.g},${rgb.b}, 0.3)`;
    }

    if (usedLabels.includes(categories[categoryId].label)) {
      label = `${categories[categoryId].label} (${duplicateLabelFixer})`;
      duplicateLabelFixer++;
    } else {
      usedLabels.push(categories[categoryId].label);
    }

    if (rollupType === ROLLUP_TYPE_DAY) {
      categoryData = getCategoryDataByDay(
        trackers,
        trackersSort[categoryId],
        filteredTrackers,
        startDate,
        endDate,
        entries,
        byScore
      );
    } else if (rollupType === ROLLUP_TYPE_WEEK) {
      categoryData = getCategoryDataByWeek(
        trackers,
        trackersSort[categoryId],
        filteredTrackers,
        startDate,
        endDate,
        entries,
        byScore
      );
    } else {
      categoryData = getCategoryDataByMonth(
        trackers,
        trackersSort[categoryId],
        filteredTrackers,
        startDate,
        endDate,
        entries,
        byScore
      );
    }

    if (categoryData) {
      data.push({
        id: label,
        color: colour,
        data: categoryData
      });
    }
  });

  return data;
};

const getAllData = (
  rollupType,
  entries,
  startDate,
  endDate,
  trackers,
  filteredTrackers,
  byScore
) => {
  const colour = byScore ? "#f90" : "#09f";
  const label = byScore ? "Score" : "Total";
  let allData = [];

  if (rollupType === ROLLUP_TYPE_DAY) {
    allData = getAllDataByDay(
      trackers,
      filteredTrackers,
      startDate,
      endDate,
      entries,
      byScore
    );
  } else if (rollupType === ROLLUP_TYPE_WEEK) {
    allData = getAllDataByWeek(
      trackers,
      filteredTrackers,
      startDate,
      endDate,
      entries,
      byScore
    );
  } else {
    allData = getAllDataByMonth(
      trackers,
      filteredTrackers,
      startDate,
      endDate,
      entries,
      byScore
    );
  }

  return [{ id: label, color: colour, data: allData }];
};

const getData = (
  lineType,
  rollupType,
  entries,
  startDate,
  endDate,
  trackers,
  filteredTrackers,
  trackersSort,
  categories,
  categoriesSort,
  byScore,
  highlightedItem
) => {
  let chartData = [];

  if (lineType === LINE_TYPE_ALL) {
    chartData = getAllData(
      rollupType,
      entries,
      startDate,
      endDate,
      trackers,
      filteredTrackers,
      byScore
    );
  } else if (lineType === LINE_TYPE_CATEGORIES) {
    chartData = getCategoryData(
      rollupType,
      entries,
      startDate,
      endDate,
      trackers,
      filteredTrackers,
      trackersSort,
      categories,
      categoriesSort,
      byScore,
      highlightedItem
    );
  } else if (lineType === LINE_TYPE_TRACKERS) {
    chartData = getTrackerData(
      rollupType,
      entries,
      startDate,
      endDate,
      trackers,
      filteredTrackers,
      trackersSort,
      byScore,
      highlightedItem
    );
  }

  return chartData;
};

const Chart = ({
  user,
  compact,
  handleChangeDisplayType,
  displayType,
  setDateRange,
  entries,
  startDate,
  endDate,
  trackers,
  filteredTrackers,
  categoriesSort,
  trackersSort,
  categories,
  sidebarCollapsed
}) => {
  const refContainer = useRef(null);
  const [byScore, setByScore] = useState(false);
  const [highlightedItem, setHighlightedItem] = useState(null);
  const [lineType, setLineType] = useState(LINE_TYPE_ALL);
  const [rollupType, setRollupType] = useState(ROLLUP_TYPE_DAY);

  const [setDataTimeRollupByWidth] = useDebouncedCallback(
    (startDate, endDate, compact, lineType) => {
      const daysInRange = endDate.diff(startDate, "days");
      const width = refContainer.current.offsetWidth;
      const chartPadding =
        56 + !compact && lineType !== LINE_TYPE_ALL ? 200 : 20;
      const spacePerDay = Math.floor((width - chartPadding) / daysInRange);

      if (spacePerDay > WEEK_THRESH) {
        setRollupType(ROLLUP_TYPE_DAY);
      } else if (spacePerDay > MONTH_THRESH) {
        setRollupType(ROLLUP_TYPE_WEEK);
      } else {
        setRollupType(ROLLUP_TYPE_MONTH);
      }
    },
    250
  );

  const onToggleByScore = () => {
    setByScore(!byScore);
    localStorage.setItem(`${user.uid}:ch:dm`, !byScore);
  };

  const onToggleLineType = key => {
    setLineType(key);
    localStorage.setItem(`${user.uid}:ch:lt`, key);
  };

  useEffect(() => {
    if (endDate.isAfter(moment(), "day")) {
      setDateRange({
        startDate: startDate,
        endDate: moment()
      });
    }
  }, [endDate, setDateRange, startDate]);

  useEffect(() => {
    const byScore = localStorage.getItem(`${user.uid}:ch:dm`);
    const lineType = localStorage.getItem(`${user.uid}:ch:lt`);

    if (byScore) {
      setByScore(JSON.parse(byScore));
    }

    if (lineType && lineTypes.includes(lineType)) {
      setLineType(lineType);
    }
  }, [user.uid]);

  useEffect(() => {
    const resizeFunc = () =>
      setDataTimeRollupByWidth(startDate, endDate, compact, lineType);

    setDataTimeRollupByWidth(startDate, endDate, compact, lineType);
    window.addEventListener("resize", resizeFunc);

    return () => {
      window.removeEventListener("resize", resizeFunc);
    };
  }, [compact, startDate, endDate, lineType, setDataTimeRollupByWidth]);

  useEffect(() => {
    setDataTimeRollupByWidth(startDate, endDate, compact, lineType);
  }, [
    compact,
    startDate,
    endDate,
    lineType,
    setDataTimeRollupByWidth,
    sidebarCollapsed
  ]);

  let chartData = getData(
    lineType,
    rollupType,
    entries,
    startDate,
    endDate,
    trackers,
    filteredTrackers,
    trackersSort,
    categories,
    categoriesSort,
    byScore,
    highlightedItem
  );

  return (
    <div className="ChartDisplay" ref={refContainer}>
      <ChartControls
        setDateRange={setDateRange}
        startDate={startDate}
        endDate={endDate}
        displayType={displayType}
        handleChangeDisplayType={handleChangeDisplayType}
        lineType={lineType}
        setLineType={onToggleLineType}
        byScore={byScore}
        onToggleByScore={onToggleByScore}
      />
      <div className="ChartDisplay__Chart">
        <MyResponsiveLine
          rollupType={rollupType}
          byScore={byScore}
          lineType={lineType}
          data={chartData}
          compact={compact}
          setHighlightedItem={setHighlightedItem}
        />
      </div>
    </div>
  );
};

Chart.propTypes = {
  handleChangeDisplayType: PropTypes.func.isRequired,
  displayType: PropTypes.string.isRequired,
  compact: PropTypes.bool.isRequired,
  sidebarCollapsed: PropTypes.bool.isRequired,
  setDateRange: PropTypes.func.isRequired,
  entries: PropTypes.object,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  trackers: PropTypes.object,
  filteredTrackers: PropTypes.array,
  onDayClick: PropTypes.func.isRequired,
  userDocRef: PropTypes.object,
  selectedDataSet: PropTypes.string,
  categoriesSort: PropTypes.array,
  trackersSort: PropTypes.object,
  categories: PropTypes.object
};

export default Chart;
