import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./css/DataSetSelector.css";

const DataSetSelector = ({
  userDocRef,
  selectedDataSet,
  handleDataSetChange
}) => {
  const [dataSets, setDataSets] = useState();

  useEffect(() => {
    let unSubDataSetsSnapshot = userDocRef
      .collection("dataSets")
      .onSnapshot(snapShot => {
        let dataSets = {};
        if (snapShot.size >= 1) {
          snapShot.forEach(doc => {
            dataSets[doc.id] = doc.data();
          });
        }

        setDataSets(dataSets);
      });

    return () => {
      if (unSubDataSetsSnapshot) {
        unSubDataSetsSnapshot();
      }
    };
  }, [userDocRef]);

  if (!dataSets) return null;

  let sortedDataSets = Object.keys(dataSets)
    .map(key => ({
      id: key,
      label: dataSets[key].label,
      dateAdded: dataSets[key].dateAdded
    }))
    .sort((a, b) => {
      return a.dateAdded.seconds > b.dateAdded.seconds;
    });

  return (
    <div className="DataSets">
      <div>
        <label htmlFor="SelectedDataSet">Dataset</label>
        <select
          name="SelectedDataSet"
          onChange={e => handleDataSetChange(e.target.value)}
          value={selectedDataSet}
        >
          {sortedDataSets.map(dataSet => {
            return (
              <option key={dataSet.id} value={dataSet.id}>
                {dataSet.label}
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
};

DataSetSelector.propTypes = {
  userDocRef: PropTypes.object,
  selectedDataSet: PropTypes.string,
  handleDataSetChange: PropTypes.func
};

export default DataSetSelector;
