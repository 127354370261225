import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import "./css/SignUpForm.css";
import { fireauth } from "../lib/firebase";

const SignUpForm = () => {
  const [error, setError] = useState(null);

  const createAccount = (email, password) => {
    fireauth.createUserWithEmailAndPassword(email, password).catch(error => {
      setError(error.message);
    });
  };

  return (
    <div className="SignUpForm">
      <h2>Sign Up</h2>
      <p>It's free!</p>
      <Formik
        initialValues={{ email: "", password: "" }}
        validate={values => {
          const errors = {};

          if (!values.email) {
            errors.email = "Required";
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = "Invalid email address";
          }

          if (!values.password) {
            errors.password = "Required";
          } else if (values.password.length < 6) {
            errors.password = "Password must be at least 6 characters long";
          }

          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          createAccount(values.email, values.password);
          setSubmitting(false);
        }}
      >
        {({ errors, touched, isSubmitting }) => (
          <Form>
            <ul>
              <li
                className={errors.email && touched.email ? "field-error" : ""}
              >
                <label htmlFor="email">Email</label>
                <Field type="text" name="email" autoComplete="email" />
                <ErrorMessage
                  name="email"
                  className="SignUpForm__Error"
                  component="div"
                />
              </li>
              <li
                className={
                  errors.password && touched.password ? "field-error" : ""
                }
              >
                <label htmlFor="password">Password</label>
                <Field type="password" name="password" />
                <ErrorMessage
                  name="password"
                  className="SignUpForm__Error"
                  component="div"
                />
              </li>
              <li>
                <button type="submit" disabled={isSubmitting}>
                  Create Account
                </button>
              </li>
            </ul>
            {error && (
              <div className="SignUpForm__Errors">
                <p>{error}</p>
              </div>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default SignUpForm;
