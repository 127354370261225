const modalStyles = {
  overlay: {
    zIndex: 1,
    backgroundColor: "rgba(0, 0, 0, 0.95)",
    //
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  content: {
    border: 0,
    borderRadius: 0,
    maxHeight: "calc(100vh - 5em)",
    overflowY: "auto",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    // marginRight: "-50%",
    padding: "0",
    // transform: "translate(-50%, -50%)"
    position: "static"
  }
};

export default modalStyles;
