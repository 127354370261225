import React from "react";
import "./css/Day.css";
import PropTypes from "prop-types";
import moment from "moment";
import { DATE_FORMAT } from "../../../lib/constants.js";
import Emoji from "../../Emoji";
import DayEmojis from "./Days/DayEmojis";
import DayTinySquares from "./Days/DayTinySquares";
import DaySquares from "./Days/DaySquares";
import DayPoints from "./Days/DayPoints";
import DayTotal from "./Days/DayTotal";
import monthEmojis from "../../../lib/monthEmojis";

const Days = {
  DayEmojis,
  DayTinySquares,
  DaySquares,
  DayPoints,
  DayTotal
};

const DynamicDay = ({
  date,
  entry,
  trackers,
  flattenedTrackersSort,
  filteredTrackers,
  monthLabel,
  yearLabel,
  displayMode,
  selectedDate,
  onDayClick
}) => {
  const items = entry && entry.items ? entry.items : null;
  const dateObj = moment(date, DATE_FORMAT);
  const weekday = dateObj.format("dddd");
  const isWeekend = weekday === "Saturday" || weekday === "Sunday";
  const isToday = date === moment().format(DATE_FORMAT);
  const isFuture = dateObj >= moment();
  const isMonthStart = 1 === parseInt(dateObj.format("D"), 10);
  const Day = Days[displayMode];

  if (!Day) return null;

  let props = !isFuture
    ? { tabIndex: "0", onClick: () => onDayClick(dateObj) }
    : {};

  return (
    <div
      className={[
        `Day Day--${displayMode}`,
        isToday ? "Day--today" : "",
        isFuture ? "Day--future" : "",
        isMonthStart ? "Day--month-start" : "",
        isWeekend ? "Day--weekend" : "Day--weekday",
        `Day--${dateObj.format("dddd").toLowerCase()}`,
        dateObj.isSame(selectedDate, "day") ? "Day--selected" : ""
      ].join(" ")}
      {...props}
    >
      {yearLabel && (
        <div className="Day__year-label">
          <Emoji emoji={monthEmojis[dateObj.format("M")]} />
          <span>{dateObj.format("YYYY")}</span>
        </div>
      )}
      {monthLabel && (
        <div className="Day__month-label">
          <Emoji emoji={monthEmojis[dateObj.format("M")]} />
          <span>{dateObj.format("MMMM")}</span>
        </div>
      )}
      <Day
        items={items}
        trackers={trackers}
        flattenedTrackersSort={flattenedTrackersSort}
        filteredTrackers={filteredTrackers}
      />
      {!isFuture && (
        <div className="Day__Date">
          <abbr title={dateObj.format("MMMM Do, YYYY")}>
            {dateObj.format("D")}
          </abbr>
        </div>
      )}
    </div>
  );
};

DynamicDay.propTypes = {
  date: PropTypes.string.isRequired,
  items: PropTypes.array
};

DynamicDay.defaultProps = {
  items: []
};

export default DynamicDay;
