import React, { Component } from "react";
import PropTypes from "prop-types";
import { debounce } from "debounce";
import moment from "moment";

import StreakControls from "./StreakControls";
import StreakTable from "./StreakTable";

import "./Streak.css";

class Streak extends Component {
  state = {
    daysToDisplay: 3
  };
  _isMounted = false;
  _daySize = 28;
  _padding = 56;
  _trackersWidthCompact = 29;
  _trackersWidth = 160;

  constructor(props) {
    super(props);
    this.wrapperRef = React.createRef();
  }

  updateDaysToDisplayByWindowSize = () => {
    if (this._isMounted) {
      const trackerWidth = this.props.compact
        ? this._trackersWidthCompact
        : this._trackersWidth;

      const days = Math.max(
        1,
        Math.floor(
          (this.wrapperRef.current.offsetWidth - trackerWidth - this._padding) /
            this._daySize
        )
      );

      this.setState({ daysToDisplay: days }, () => {
        this.props.setDateRange({
          startDate: moment(this.props.endDate).subtract(days, "days"),
          endDate: moment(this.props.endDate)
        });
      });
    }
  };

  componentDidMount() {
    this._isMounted = true;
    if (this.props.endDate > moment()) {
      this.props.setDateRange({
        startDate: this.props.startDate,
        endDate: moment()
      });
    }
    this.updateDaysToDisplayByWindowSize();
    this.debouncedResize = debounce(this.updateDaysToDisplayByWindowSize, 200);
    window.addEventListener("resize", this.debouncedResize);
  }

  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener("resize", this.debouncedResize);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.sidebarCollapsed !== prevProps.sidebarCollapsed ||
      this.props.compact !== prevProps.compact
    ) {
      this.updateDaysToDisplayByWindowSize();
    }
  }

  // shouldComponentUpdate(nextProps, nextState) {
  //   return false;
  // }

  render() {
    if (this.props.trackersSort == null || this.props.categoriesSort == null)
      return null;

    return (
      <div className="Streak">
        <StreakControls
          setDateRange={this.props.setDateRange}
          startDate={this.props.startDate}
          endDate={this.props.endDate}
          daysToDisplay={this.state.daysToDisplay}
          displayType={this.props.displayType}
          handleChangeDisplayType={this.props.handleChangeDisplayType}
        />
        <div className="Streak__Data" ref={this.wrapperRef}>
          <StreakTable
            compact={this.props.compact}
            startDate={this.props.startDate}
            endDate={this.props.endDate}
            selectedDate={this.props.selectedDate}
            onDayClick={this.props.onDayClick}
            daysToDisplay={this.state.daysToDisplay}
            trackers={this.props.trackers}
            trackersSort={this.props.trackersSort}
            filteredTrackers={this.props.filteredTrackers}
            categoriesSort={this.props.categoriesSort}
            entries={this.props.entries}
          />
        </div>
      </div>
    );
  }
}

Streak.propTypes = {
  selectedDate: PropTypes.object,
  handleChangeDisplayType: PropTypes.func.isRequired,
  displayType: PropTypes.string.isRequired,
  compact: PropTypes.bool.isRequired,
  sidebarCollapsed: PropTypes.bool.isRequired,
  setDateRange: PropTypes.func.isRequired,
  entries: PropTypes.object,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  trackers: PropTypes.object,
  filteredTrackers: PropTypes.array,
  onDayClick: PropTypes.func.isRequired,
  userDocRef: PropTypes.object,
  selectedDataSet: PropTypes.string,
  categoriesSort: PropTypes.array,
  trackersSort: PropTypes.object
};

export default Streak;
