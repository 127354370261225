import React from "react";
import "./css/Week.css";
import PropTypes from "prop-types";
import DynamicDay from "./DynamicDay";
import moment from "moment";
import { DATE_FORMAT } from "../../../lib/constants.js";

const Week = ({
  startDate,
  entries,
  trackers,
  flattenedTrackersSort,
  filteredTrackers,
  displayMode,
  selectedDate,
  onDayClick
}) => {
  let days = [];
  let start = moment(startDate, DATE_FORMAT);
  let end = moment(startDate, DATE_FORMAT).add(7, "d");

  for (var i = 0, s = moment(start); s.isBefore(end); s.add(1, "d"), i++) {
    days.push(
      <DynamicDay
        key={s.format(DATE_FORMAT)}
        date={s.format(DATE_FORMAT)}
        entry={entries[s.format(DATE_FORMAT)]}
        trackers={trackers}
        flattenedTrackersSort={flattenedTrackersSort}
        filteredTrackers={filteredTrackers}
        monthLabel={s.date() === 1 && s.dayOfYear() !== 1}
        yearLabel={s.dayOfYear() === 1}
        displayMode={displayMode}
        onDayClick={onDayClick}
        selectedDate={selectedDate}
      />
    );
  }

  return <div className="Week">{days}</div>;
};

Week.propTypes = {
  startDate: PropTypes.string.isRequired
};

export default Week;
