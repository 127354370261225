import React, { useState } from "react";
import Modal from "react-modal";
import { Formik, Form, Field, ErrorMessage } from "formik";
import modalStyles from "../../../lib/modalStyles";
import { fireauth, EmailAuthProvider } from "../../../lib/firebase";

Modal.setAppElement("#root");

const ChangePasswordModal = ({ modalIsOpen, closeModal }) => {
  const [authNeeded, setAuthNeeded] = useState(true);
  const [authError, setAuthError] = useState(null);
  const [error, setError] = useState(null);
  const [passwordChanged, setPasswordChanged] = useState(false);

  const onClose = () => {
    setAuthNeeded(true);
    setAuthError(null);
    setError(null);
    setPasswordChanged(false);
    closeModal();
  };

  const authenticate = password => {
    const user = fireauth.currentUser;
    const credential = EmailAuthProvider.credential(user.email, password);

    user
      .reauthenticateWithCredential(credential)
      .then(() => {
        setAuthError(false);
        setAuthNeeded(false);
      })
      .catch(error => {
        if (error.code === "auth/wrong-password") {
          setAuthError("Invalid password.");
        } else {
          setAuthError(error.message);
        }
      });
  };

  const changePassword = newPassword => {
    fireauth.currentUser
      .updatePassword(newPassword)
      .then(() => {
        setError(false);
        setPasswordChanged(true);
      })
      .catch(error => {
        if (error.code === "auth/requires-recent-login") {
          setAuthNeeded(true);
        } else {
          setError(error.message);
        }
      });
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={onClose}
      contentLabel="Change Password Modal"
      style={modalStyles}
    >
      <div className="ChangePasswordModal modal-content modal-form">
        <div className="modal-header">
          <h2>Change Password</h2>
          <div>
            <button className="modal-close" onClick={onClose}>
              Close
            </button>
          </div>
        </div>
        {authNeeded && (
          <div className="ChangePasswordModal__AuthForm">
            <Formik
              initialValues={{
                password: ""
              }}
              validate={values => {
                const errors = {};

                if (!values.password) {
                  errors.password = "Required";
                }

                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
                authenticate(values.password);
                setSubmitting(false);
              }}
            >
              {({ errors, touched, isSubmitting }) => (
                <>
                  <Form>
                    <ul>
                      <li
                        className={
                          (errors.password || authError) && touched.password
                            ? "field-error"
                            : ""
                        }
                      >
                        <label htmlFor="password">Current Password</label>
                        <Field type="password" name="password" />
                        <ErrorMessage
                          name="password"
                          className="modal-form__error"
                          component="div"
                        />
                        {authError && (
                          <div className="modal-form__error">
                            <p>{authError}</p>
                          </div>
                        )}
                      </li>
                      <li>
                        <button type="submit" disabled={isSubmitting}>
                          Authenticate
                        </button>
                      </li>
                    </ul>
                  </Form>
                </>
              )}
            </Formik>
          </div>
        )}
        {!authNeeded && !passwordChanged && (
          <div className="ChangePasswordModal__NewForm">
            <Formik
              initialValues={{
                newPassword: "",
                confirmPassword: ""
              }}
              validate={values => {
                const errors = {};

                if (!values.newPassword) {
                  errors.newPassword = "Required";
                }

                if (values.newPassword && values.newPassword.length < 6) {
                  errors.newPassword =
                    "Password must be at least 6 characters long";
                }

                if (!values.confirmPassword) {
                  errors.confirmPassword = "Required";
                }

                if (values.newPassword !== values.confirmPassword) {
                  errors.confirmPassword = "Does not match new password";
                }

                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
                changePassword(values.newPassword, values.confirmPassword);
                setSubmitting(false);
              }}
            >
              {({ errors, touched, isSubmitting }) => (
                <>
                  <Form>
                    <ul>
                      <li
                        className={
                          errors.newPassword && touched.newPassword
                            ? "field-error"
                            : ""
                        }
                      >
                        <label htmlFor="newPassword">New Password</label>
                        <Field
                          type="password"
                          name="newPassword"
                          disabled={authNeeded}
                        />
                        <ErrorMessage
                          name="newPassword"
                          className="modal-form__error"
                          component="div"
                        />
                      </li>
                      <li
                        className={
                          errors.confirmPassword && touched.confirmPassword
                            ? "field-error"
                            : ""
                        }
                      >
                        <label htmlFor="confirmPassword">
                          Confirm New Password
                        </label>
                        <Field
                          type="password"
                          name="confirmPassword"
                          disabled={authNeeded}
                        />
                        <ErrorMessage
                          name="confirmPassword"
                          className="modal-form__error"
                          component="div"
                        />
                      </li>
                      <li>
                        <button
                          type="submit"
                          disabled={isSubmitting || authNeeded}
                        >
                          Change Password
                        </button>
                      </li>
                    </ul>
                  </Form>
                  {error && (
                    <div className="modal-form__error">
                      <p>{error}</p>
                    </div>
                  )}
                </>
              )}
            </Formik>
          </div>
        )}
        {passwordChanged && <p>Your password has been changed.</p>}
      </div>
    </Modal>
  );
};

export default ChangePasswordModal;
