import React, { Component } from "react";
import moment from "moment";
import { debounce } from "debounce";

import {
  DATE_FORMAT,
  DISPLAY_DAY_TYPE_EMOJIS,
  DISPLAY_DAY_TYPE_TINYSQUARES,
  DISPLAY_DAY_TYPE_SQUARES,
  DISPLAY_DAY_TYPE_POINTS,
  DISPLAY_DAY_TYPE_TOTAL
} from "../../../lib/constants.js";
import { flattenTrackersSort } from "../../../lib/utils.js";

import Week from "./Week";
import TimelineControls from "./TimelineControls";

import "./css/Timeline.css";

const displayModes = [
  DISPLAY_DAY_TYPE_EMOJIS,
  DISPLAY_DAY_TYPE_TINYSQUARES,
  DISPLAY_DAY_TYPE_SQUARES,
  DISPLAY_DAY_TYPE_POINTS,
  DISPLAY_DAY_TYPE_TOTAL
];

class Timeline extends Component {
  _isMounted = false;
  _daySize = 100;
  _padding = 56;
  _dowWidthCompact = 0;
  _dowWidth = 46;
  state = {
    tlEndDate: moment(),
    weeksToDisplay: 1,
    displayMode: DISPLAY_DAY_TYPE_EMOJIS,
    flattenedTrackersSort: flattenTrackersSort(
      this.props.trackersSort,
      this.props.categoriesSort
    )
  };

  constructor(props) {
    super(props);

    this.wrapperRef = React.createRef();
  }

  updateWeeksToDisplay = () => {
    if (this._isMounted) {
      const dowWidth = this.props.compact
        ? this._dowWidthCompact
        : this._dowWidth;

      const weeks = Math.max(
        1,
        Math.floor(
          (this.wrapperRef.current.offsetWidth -
            this._padding -
            dowWidth -
            this._daySize) /
            this._daySize
        )
      );

      this.setState({ weeksToDisplay: weeks }, () => {
        this.setDateRange(this.state.tlEndDate);
      });
    }
  };

  componentDidMount() {
    this._isMounted = true;
    this.updateWeeksToDisplay();
    this.debouncedResize = debounce(this.updateWeeksToDisplay, 200);
    window.addEventListener("resize", this.debouncedResize);

    let displayMode = localStorage.getItem(`${this.props.user.uid}:tl:dm`);
    if (displayModes.includes(displayMode)) {
      this.setState({ displayMode });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener("resize", this.debouncedResize);
  }

  componentDidUpdate(prevProps) {
    if (this.props.sidebarCollapsed !== prevProps.sidebarCollapsed) {
      this.updateWeeksToDisplay();
    }

    if (this.props.trackersSort !== prevProps.trackersSort) {
      this.setState({
        flattenedTrackersSort: flattenTrackersSort(
          this.props.trackersSort,
          this.props.categoriesSort
        )
      });
    }
  }

  changeDisplayMode = displayMode => {
    this.setState(
      {
        displayMode
      },
      () => {
        localStorage.setItem(`${this.props.user.uid}:tl:dm`, displayMode);
      }
    );
  };

  setDateRange = date => {
    let newEndDate = moment(date)
      .endOf("month")
      .endOf("isoweek");

    let tlEndDate = moment(date)
      .endOf("month")
      .startOf("isoweek");

    if (tlEndDate.isAfter(moment())) {
      tlEndDate = moment().endOf("isoweek");
    }

    this.setState({ tlEndDate });

    let newStartDate = moment(date).subtract(
      this.state.weeksToDisplay + 1,
      "weeks"
    );

    this.props.setDateRange({ startDate: newStartDate, endDate: newEndDate });
  };

  render() {
    const { trackers, filteredTrackers, selectedDate, onDayClick } = this.props;
    let weeks = [];

    for (let i = this.state.weeksToDisplay; i >= 0; i--) {
      let nextStart = moment(this.state.tlEndDate)
        .startOf("isoWeek")
        .subtract(i * 7, "d");

      weeks.push(
        <Week
          key={nextStart.format(DATE_FORMAT)}
          startDate={nextStart.format(DATE_FORMAT)}
          entries={this.props.entries}
          trackers={trackers}
          flattenedTrackersSort={this.state.flattenedTrackersSort}
          filteredTrackers={filteredTrackers}
          selectedDate={selectedDate}
          onDayClick={onDayClick}
          displayMode={this.state.displayMode}
        />
      );
    }

    return (
      <div
        className={[
          "Timeline",
          `Timeline--display-type-${this.state.displayMode}`
        ].join(" ")}
      >
        <TimelineControls
          handleChangeDisplayType={this.props.handleChangeDisplayType}
          endDate={this.state.tlEndDate}
          displayMode={this.state.displayMode}
          displayType={this.props.displayType}
          changeDisplayMode={this.changeDisplayMode}
          setDateRange={this.setDateRange}
        />
        <div className="Timeline__Calendar" ref={this.wrapperRef}>
          <div>
            {!this.props.compact && (
              <div
                className="Timeline__DOWs"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <div className="Timeline__DOW">
                  <abbr title="Mondays">
                    {moment()
                      .day(1)
                      .format("ddd")
                      .charAt(0)}
                  </abbr>
                </div>
                <div className="Timeline__DOW">
                  <abbr title="Tuesdays">
                    {moment()
                      .day(2)
                      .format("ddd")
                      .charAt(0)}
                  </abbr>
                </div>
                <div className="Timeline__DOW">
                  <abbr title="Wednesdays">
                    {moment()
                      .day(3)
                      .format("ddd")
                      .charAt(0)}
                  </abbr>
                </div>
                <div className="Timeline__DOW">
                  <abbr title="Thursdays">
                    {moment()
                      .day(4)
                      .format("ddd")
                      .charAt(0)}
                  </abbr>
                </div>
                <div className="Timeline__DOW">
                  <abbr title="Fridays">
                    {moment()
                      .day(5)
                      .format("ddd")
                      .charAt(0)}
                  </abbr>
                </div>
                <div className="Timeline__DOW Timeline__DOW--weekend">
                  <abbr title="Saturdays">
                    {moment()
                      .day(6)
                      .format("ddd")
                      .charAt(0)}
                  </abbr>
                </div>
                <div className="Timeline__DOW Timeline__DOW--weekend">
                  <abbr title="Sundays">
                    {moment()
                      .day(0)
                      .format("ddd")
                      .charAt(0)}
                  </abbr>
                </div>
              </div>
            )}
            {weeks}
          </div>
        </div>
      </div>
    );
  }
}

export default Timeline;
