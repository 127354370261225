import React from "react";
// import PropTypes from "prop-types";
import SignInForm from "../SignInForm";
import SignUpForm from "../SignUpForm";
import { ReactComponent as SmIcon } from "../../images/starmemory-icon-V2.svg";
import { ReactComponent as SmLogo } from "../../images/starmemory-wordmark.svg";
import { ReactComponent as AppleIcon } from "../../images/apple.svg";
import { ReactComponent as PatternIcon } from "../../images/pattern.svg";
import { ReactComponent as NoteIcon } from "../../images/note.svg";
import choresExample from "../../images/examples/chores.png";
import dietExample from "../../images/examples/diet.png";
import exerciesExample from "../../images/examples/exercises.png";
import hobbiesExample from "../../images/examples/hobbies.png";
import habitsExample from "../../images/examples/habits.png";
import moodExample from "../../images/examples/mood.png";
import painExample from "../../images/examples/pain.png";
import periodsExample from "../../images/examples/periods.png";
import projectsExample from "../../images/examples/projects.png";
import streakDetail from "../../images/details/streak.png";
import filteredDetail from "../../images/details/filtered.png";
import customDetail from "../../images/details/custom.png";
import reorderDetail from "../../images/details/reorder.png";
import scoreDetail from "../../images/details/score.png";
import notesDetail from "../../images/details/notes.png";
import "./css/SignedOut.css";
import "../../css/Examples.css";
import "../../css/Details.css";

const SignedOut = () => {
  return (
    <div className="SignedOut">
      <div className="SignedOut__Header">
        <div>
          <h1>
            <SmIcon />
            <SmLogo alt="Starmemory" />
          </h1>
          <div className="SignedOut__SignIn">
            <SignInForm />
          </div>
        </div>
      </div>
      <div className="SignedOut__Pitch">
        <div className="SignedOut__Copy">
          <h2>Keep Track of What's Important.</h2>
          <ul>
            <li>
              <div>
                <AppleIcon />
              </div>
              <div>
                <strong>Build habits</strong>
                <br />
                Start a streak of good things and keep it going!
              </div>
            </li>
            <li>
              <div>
                <PatternIcon />
              </div>
              <div>
                <strong>Find Patterns</strong>
                <br />
                See your life in ways you never have before!
              </div>
            </li>
            <li>
              <div>
                <NoteIcon />
              </div>
              <div>
                <strong>Take Notes</strong>
                <br />
                Write daily notes for future you!
              </div>
            </li>
          </ul>
        </div>
        <div className="SignedOut__SignUp">
          <SignUpForm />
        </div>
      </div>
      <div className="SignedOut__Examples">
        <div className="Examples">
          <h2>Starmemory Ideas</h2>
          <p>
            You can customize it to track <em>anything</em> you want! Here are
            some examples of how you might use Starmemory:
          </p>
          <div className="Examples__Items">
            <ul>
              <li>
                <h3>Exercises</h3>
                <img
                  src={exerciesExample}
                  alt="Example of exercises you could track, like pushups, squat jumps, crunches etc."
                />
              </li>
              <li>
                <h3>Hobbies</h3>
                <img
                  src={hobbiesExample}
                  alt="Example of hobbies you could track, like language learning, practicing guitar, drawing etc."
                />
              </li>
              <li>
                <h3>Mood</h3>
                <img
                  src={moodExample}
                  alt="Example of moods you could track, like chill, happy, anxious etc."
                />
              </li>
              <li>
                <h3>Habits</h3>
                <img
                  src={habitsExample}
                  alt="Example of healthy habits you could track, like gym visits, jogging, stretches etc."
                />
              </li>
              <li>
                <h3>Projects</h3>
                <img
                  src={projectsExample}
                  alt="Example of personal projects you could track, like working on your cool app, drawing a webcomic, quilting etc."
                />
              </li>
              <li>
                <h3>Pain</h3>
                <img
                  src={painExample}
                  alt="Example of pain you could track, like back pain, joint pain, headaches etc."
                />
              </li>
              <li>
                <h3>Diet</h3>
                <img
                  src={dietExample}
                  alt="Example of diet things you could track, like servings of fruits/veggies, glasses of water you drank, how much junk food you ate etc."
                />
              </li>
              <li>
                <h3>Chores</h3>
                <img
                  src={choresExample}
                  alt="Example of chores you could track, like laundry, dishes, groceries etc."
                />
              </li>
              <li>
                <h3>Periods</h3>
                <img
                  src={periodsExample}
                  alt="Example of period symptoms could track, like cramps, nausea, fatique etc."
                />
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="SignedOut__Details">
        <ul className="Details">
          <li>
            <h2>Data Visualization</h2>
            <div className="Detail">
              <div>
                <p>
                  Starmemory includes various ways to view the data you are
                  tracking, to help you gain insights about the things you track
                  by discovering patterns within your data.
                </p>
              </div>
              <div className="Details__Image">
                <img src={streakDetail} alt="Streak data view" />
              </div>
            </div>
            <div className="Detail Detail--reverse">
              <div>
                <p>
                  Toggle trackers and categories on and off to narrow down the
                  data you're currently viewing. Compare one tracker to another
                  to and find relationships in your data!
                </p>
              </div>
              <div className="Details__Image">
                <img src={filteredDetail} alt="Filtered streak data view" />
              </div>
            </div>
          </li>
          <li>
            <h2>Customization</h2>
            <div className="Detail">
              <div>
                <p>
                  Name your trackers anything you want, then choose a colour and
                  an emojis to help you distinquish and organize them.
                </p>
              </div>
              <div className="Details__Image">
                <img src={customDetail} alt="Tracker edit interface" />
              </div>
            </div>
            <div className="Detail Detail--reverse">
              <div>
                <p>
                  Create categories and organize them in any way you want.
                  Easily move trackers between categories, and re-order them
                  with a simple drag and drop interface.
                </p>
              </div>
              <div className="Details__Image">
                <img src={reorderDetail} alt="Customize tracker categories" />
              </div>
            </div>
          </li>
          <li>
            <h2>Daily Scores</h2>
            <div className="Detail">
              <div>
                <p>
                  Trackers can be setup to include a point value. See your score
                  as you increase tracker values each day. Compete with yourself
                  for the highest score!
                </p>
              </div>
              <div className="Details__Image">
                <img
                  src={scoreDetail}
                  alt="Scores from tracker points for each day"
                />
              </div>
            </div>
          </li>
          <li>
            <h2>Daily Notes</h2>
            <div className="Detail Detail--reverse">
              <div>
                <p>
                  Write notes for each day, perfect for adding extra details
                  about what you're tracking, or use it as a mini journal.
                </p>
              </div>
              <div className="Details__Image">
                <img src={notesDetail} alt="An example of a daily note" />
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div className="Footer">
        <p>
          <small>&copy; {new Date().getFullYear()} starmemory.ca</small>
        </p>
      </div>
    </div>
  );
};

SignedOut.propTypes = {};

export default SignedOut;
