export const trackerColours = [
  "#bf1a02",
  "#c14f0c",
  "#c58700",
  "#aaaa00",
  "#15971c",
  "#0073a1",
  "#3333a6",
  "#772391",
  "#9d237e",

  "#ff5050",
  "#ff7f34",
  "#ffd030",
  "#f9fc3a",
  "#78f062",
  "#4fbbff",
  "#5e81f8",
  "#bf3bf2",
  "#fc4dcc",

  "#ffa7a8",
  "#ffba8c",
  "#ffe990",
  "#ffff9d",
  "#a5ffab",
  "#a5e8ff",
  "#afc7ff",
  "#edb6ff",
  "#ffc5f2",

  "#000",
  "#212121",
  "#434343",
  "#666",
  "#898989",
  "#acacac",
  "#cdcdcd",
  "#e4e4e4",
  "#fff"
];

export const chartColours = [
  "#ff5050",
  "#ff7f34",
  "#ffd030",
  "#f9fc3a",
  "#78f062",
  "#4fbbff",
  "#5e81f8",
  "#bf3bf2",
  "#fc4dcc",
  "#ffa7a8",
  "#ffba8c",
  "#ffe990",
  "#ffff9d",
  "#a5ffab",
  "#a5e8ff",
  "#afc7ff",
  "#edb6ff",
  "#ffc5f2",
  "#bf1a02",
  "#c14f0c",
  "#c58700",
  "#aaaa00",
  "#15971c",
  "#0073a1",
  "#3333a6",
  "#772391",
  "#9d237e"
];
