// Third Party Libraries
import React, { Component } from "react";
import PropTypes from "prop-types";
import Octicon, { ChevronRight } from "@primer/octicons-react";

// React components
import DetailDay from "./DetailDay/DetailDay";
import Edit from "./Edit/Edit";
import Settings from "./Settings/Settings";
import Loader from "../Loader";
import Tabs from "../Tabs";

// Utils etc.
import {
  VIEW_DETAIL_DAY,
  VIEW_EDIT_TRACKERS,
  VIEW_ACCOUNT_SETTINGS
} from "../../lib/constants.js";

// CSS
import "./css/Sidebar.css";

// TODO: switch to functional comment with useState/useEffect hooks
class Sidebar extends Component {
  state = {
    selectedTab: VIEW_DETAIL_DAY,
    collapsedCategories: []
  };

  componentDidMount() {
    this.collapsedCategoryId = `${this.props.user.uid}:ccats`;

    let collapsedCategories = JSON.parse(
      localStorage.getItem(this.collapsedCategoryId)
    );

    if (collapsedCategories === null) {
      collapsedCategories = [];
      localStorage.setItem(
        this.collapsedCategoryId,
        JSON.stringify(collapsedCategories)
      );
    }

    this.setState({
      collapsedCategories
    });
  }

  toggleCategoryCollapse = categoryId => {
    let collapsedCategories;

    this.setState(
      prevState => {
        collapsedCategories = [...prevState.collapsedCategories];
        const index = collapsedCategories.indexOf(categoryId);

        if (index < 0) {
          collapsedCategories.push(categoryId);
        } else {
          collapsedCategories.splice(index, 1);
        }

        return {
          collapsedCategories: collapsedCategories
        };
      },
      () => {
        localStorage.setItem(
          this.collapsedCategoryId,
          JSON.stringify(collapsedCategories)
        );
      }
    );
  };

  render() {
    const {
      usedEmojisData,
      user,
      userData,
      selectedDate,
      trackers,
      trackersSort,
      categories,
      categoriesSort,
      setSelectedDay,
      sidebarCollapsed,
      handleCollapseSidebar,
      //
      handleDataSetChange,
      selectedDataSet,
      //
      handleAddNewTracker,
      handleOnChangeTracker,
      updateTrackerDetails,
      handleConfirmDeleteTracker,
      addingTrackers,
      //
      handleAddNewCategory,
      handleChangeCategoryLabel,
      handleConfirmDeleteCategory,
      addingCategory,
      //
      filteredTrackers,
      toggleCategoryFiltered,
      toggleTrackerFiltered
    } = this.props;

    let view = null;

    if (this.props.loadingDataset) {
      view = <Loader alt="Loading Data" />;
    } else if (this.props.selectedTab === VIEW_EDIT_TRACKERS) {
      view = (
        <Edit
          usedEmojisData={usedEmojisData}
          trackers={trackers}
          trackersSort={trackersSort}
          categories={categories}
          categoriesSort={categoriesSort}
          //
          handleAddNewTracker={handleAddNewTracker}
          handleOnChangeTracker={handleOnChangeTracker}
          updateTrackerDetails={updateTrackerDetails}
          handleConfirmDeleteTracker={handleConfirmDeleteTracker}
          addingTrackers={addingTrackers}
          //
          handleAddNewCategory={handleAddNewCategory}
          handleChangeCategoryLabel={handleChangeCategoryLabel}
          handleConfirmDeleteCategory={handleConfirmDeleteCategory}
          addingCategory={addingCategory}
        />
      );
    } else if (this.props.selectedTab === VIEW_DETAIL_DAY) {
      view = (
        <DetailDay
          selectedDataSet={selectedDataSet}
          setSelectedDay={setSelectedDay}
          user={user}
          date={selectedDate}
          trackers={trackers}
          trackersSort={trackersSort}
          categories={categories}
          categoriesSort={categoriesSort}
          collapsedCategories={this.state.collapsedCategories}
          toggleCategoryCollapse={this.toggleCategoryCollapse}
          filteredTrackers={filteredTrackers}
          toggleCategoryFiltered={toggleCategoryFiltered}
          toggleTrackerFiltered={toggleTrackerFiltered}
        />
      );
    } else if (this.props.selectedTab === VIEW_ACCOUNT_SETTINGS) {
      view = (
        <Settings
          user={user}
          userData={userData}
          selectedDataSet={selectedDataSet}
          handleDataSetChange={handleDataSetChange}
        />
      );
    }

    return (
      <div
        className={[
          "Sidebar",
          sidebarCollapsed ? "Sidebar--collapsed" : ""
        ].join(" ")}
      >
        {!this.props.loadingDataset &&
          !this.props.compact &&
          !sidebarCollapsed && (
            <Tabs
              showDisplayButtons={false}
              selectedTab={this.props.selectedTab}
              handleTabClick={this.props.handleTabClick}
            />
          )}
        {!sidebarCollapsed && <div className="Sidebar__View">{view}</div>}
        <div className="Sidebar__Collapse">
          <button
            className="sm-outliner"
            title={sidebarCollapsed ? "Open Sidebar" : "Close Sidebar"}
            onClick={handleCollapseSidebar}
          >
            <Octicon icon={ChevronRight} />
          </button>
        </div>
      </div>
    );
  }
}

Sidebar.defaultProps = {
  items: []
};

Sidebar.propTypes = {
  selectedDate: PropTypes.object.isRequired,
  items: PropTypes.array,
  trackers: PropTypes.object
};

export default Sidebar;
