import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDI7ZRQiCG5nGwN5k2sH2Imou5KMJzFPjc",
  authDomain: "space-flight-21a17.firebaseapp.com",
  databaseURL: "https://space-flight-21a17.firebaseio.com",
  projectId: "space-flight-21a17",
  storageBucket: "space-flight-21a17.appspot.com",
  messagingSenderId: "806910739465",
  appId: "1:806910739465:web:a7daf5e65962904d",
  measurementId: "G-N16R6L9XJL"
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

export const firestore = firebase.firestore();
export const Timestamp = firebase.firestore.Timestamp;
export const fireauth = firebase.auth();
export const EmailAuthProvider = firebase.auth.EmailAuthProvider;
export default firebase;
