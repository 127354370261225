import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as SfIcon } from "../images/starmemory-icon-V2.svg";
import "./css/Loader.css";

const Loader = ({ alt }) => (
  <div className="Loader">
    <span>
      <SfIcon alt={alt} />
    </span>
  </div>
);

Loader.defaultProps = {
  alt: ""
};

Loader.propTypes = {
  alt: PropTypes.string
};

export default Loader;
