import React from "react";
import "./css/Tracker.css";
import PropTypes from "prop-types";
import { Eye, EyeClosed } from "../../icons/Custom";
import Octicon, { Plus, Dash } from "@primer/octicons-react";
import Emoji from "../../Emoji";

const Tracker = ({
  trackerId,
  trackerData,
  itemCount,
  filtered,
  toggleTrackerFiltered,
  subtractItemFromEntry,
  addItemToEntry
}) => {
  if (!trackerData) return null;

  return (
    <div className={["Tracker", filtered ? "Tracker--filtered" : ""].join(" ")}>
      <div className="Tracker__Info">
        <button
          className="Tracker__Filtered sm-outliner"
          onClick={() => toggleTrackerFiltered(trackerId)}
          title="Toggle Tracker Filtering"
        >
          {filtered ? (
            <Octicon icon={EyeClosed} ariaLabel="Tracker Filtered" />
          ) : (
            <Octicon icon={Eye} ariaLabel="Tracker Unfiltered" />
          )}
        </button>
        <div
          className="Tracker__Emoji sm-emoji"
          style={{ backgroundColor: trackerData.colour }}
          title={trackerData.label}
        >
          <Emoji emoji={trackerData.emoji} />
        </div>
        <div className="Tracker__Label">
          <span title={trackerData.label}>{trackerData.label}</span>
        </div>
      </div>
      <div className="Tracker__Dailytotal">
        <button
          className="sm-outliner"
          disabled={itemCount === 0}
          style={{ opacity: itemCount === 0 ? "0.4" : "1" }}
          title={`Reduce ${trackerData.label}`}
          onClick={() => subtractItemFromEntry(trackerId)}
        >
          <Octicon icon={Dash} />
        </button>
        <span
          style={{
            opacity: itemCount === 0 ? "0.4" : "1",
            fontWeight: itemCount === 0 ? "normal" : "bold"
          }}
        >
          {itemCount}
        </span>
        <button
          className="sm-outliner"
          title={`Increase ${trackerData.label}`}
          onClick={() => addItemToEntry(trackerId)}
        >
          <Octicon icon={Plus} />
        </button>
      </div>
    </div>
  );
};

Tracker.propTypes = {
  direction: PropTypes.oneOf(["vertical", "horizontal"])
};

export default Tracker;
