import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { START_YEAR } from "../lib/constants.js";

const MonthOptions = ({ endDate }) => {
  let monthOptions = [];

  for (let i = 0; i < 12; i++) {
    let disabled = endDate
      ? moment(endDate).month(i) > moment().endOf("month")
      : false;

    monthOptions.push({
      index: i,
      name: moment()
        .month(i)
        .format("MMMM"),
      disabled: disabled
    });
  }

  return monthOptions.map(m => {
    return (
      <option key={m.index} value={m.index} disabled={m.disabled}>
        {m.name}
      </option>
    );
  });
};

MonthOptions.propTypes = {
  endDate: PropTypes.object
};

function DayOptions({ endDate, disableFuture }) {
  const daysInMonth = endDate.daysInMonth();
  let dayOptions = [];

  for (let i = 1; i <= daysInMonth; i++) {
    let isFuture = moment(endDate).date(i) > moment();

    dayOptions.push({
      index: i,
      name: moment(endDate)
        .date(i)
        .format("ddd Do"),
      disabled: isFuture && disableFuture
    });
  }

  return dayOptions.map(m => {
    return (
      <option key={m.index} value={m.index} disabled={m.disabled}>
        {m.name}
      </option>
    );
  });
}

DayOptions.propTypes = {
  startDate: PropTypes.object,
  disableFuture: PropTypes.bool
};

function YearOptions() {
  let yearOptions = [];

  for (let i = moment().year(); i >= START_YEAR; i--) {
    yearOptions.push({
      index: i,
      name: moment()
        .year(i)
        .format("YYYY")
    });
  }

  return yearOptions.map(m => {
    return (
      <option key={m.index} value={m.index}>
        {m.name}
      </option>
    );
  });
}

export { MonthOptions, DayOptions, YearOptions };
