import React, { Component } from "react";
import PropTypes from "prop-types";
import { Move } from "../../icons/Custom";
import Octicon, { Trashcan } from "@primer/octicons-react";
import { NEW_TRACKER_LABEL } from "../../../lib/constants.js";
import "emoji-mart/css/emoji-mart.css";
import "./css/EditableTracker.css";

class EditableTracker extends Component {
  state = {
    showColourPicker: false,
    showEmojiPicker: false,
    showPointPicker: false,
    label: this.props.tracker.label
  };

  _popupThreshold = 200;

  componentDidMount() {
    if (this.props.tracker.label === NEW_TRACKER_LABEL) {
      this.labelInput.focus();
    }
  }

  handleEditTracker = label => {
    this.setState({ label });
  };

  render() {
    const {
      trackerId,
      tracker,
      handleOnChangeTracker,
      handleConfirmDeleteTracker,
      dragHandleProps,
      openTrackerModal
    } = this.props;

    return (
      <div className="EditableTracker">
        <div
          className="EditableTracker__Fields"
          style={{
            backgroundColor: tracker.colour
          }}
        >
          <div>
            <button
              className="sm-outliner"
              {...dragHandleProps}
              title="Drag Tracker"
            >
              <Octicon icon={Move} />
            </button>
          </div>
          <div className="EditableTracker__Emoji sm-emoji">
            <button
              className="sm-outliner"
              onClick={() => openTrackerModal(trackerId)}
              type="button"
              title="Edit Tracker Details"
            >
              {tracker.emoji}
            </button>
          </div>
          <div className="EditableTracker__Label">
            <input
              type="text"
              ref={input => {
                this.labelInput = input;
              }}
              onBlur={e =>
                handleOnChangeTracker(trackerId, "label", e.target.value)
              }
              onChange={e => this.handleEditTracker(e.target.value)}
              value={this.state.label}
            />
          </div>
          <button
            className="sm-outliner"
            onClick={() => handleConfirmDeleteTracker(trackerId)}
            title="Delete Tracker"
          >
            <Octicon icon={Trashcan} ariaLabel="Delete Tracker" />
          </button>
        </div>
      </div>
    );
  }
}

EditableTracker.propTypes = {
  trackers: PropTypes.object
};

export default EditableTracker;
