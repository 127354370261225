import React from "react";
import PropTypes from "prop-types";

const Emoji = ({ emoji, label }) => (
  <span className="emoji" role="img" aria-label={label} title={label}>
    {emoji}
  </span>
);

Emoji.defaultProps = {
  label: ""
};

Emoji.propTypes = {
  emoji: PropTypes.string.isRequired,
  label: PropTypes.string
};

export default Emoji;
