import React from "react";
import PropTypes from "prop-types";

import Octicon, {
  Pulse,
  Pencil,
  Tools,
  Calendar
} from "@primer/octicons-react";
import { Starmemory, Streak } from "./icons/Custom";

import {
  DISPLAY_TYPE_TIMELINE,
  DISPLAY_TYPE_STREAK,
  DISPLAY_TYPE_CHART,
  VIEW_DETAIL_DAY,
  VIEW_EDIT_TRACKERS,
  VIEW_ACCOUNT_SETTINGS
} from "../lib/constants.js";

import "./css/Tabs.css";

const Tabs = ({ showDisplayButtons, selectedTab, handleTabClick }) => {
  return (
    <div className="Tabs">
      {showDisplayButtons && (
        <button
          className={[
            "Tab--Display",
            selectedTab === DISPLAY_TYPE_STREAK ? "is-active" : ""
          ].join(" ")}
          onClick={() => handleTabClick(DISPLAY_TYPE_STREAK)}
          title="Show Streak Display"
        >
          <Octicon icon={Streak} ariaLabel="Show Streak Display" />
        </button>
      )}
      {showDisplayButtons && (
        <button
          className={[
            "Tab--Display",
            selectedTab === DISPLAY_TYPE_TIMELINE ? "is-active" : ""
          ].join(" ")}
          onClick={() => handleTabClick(DISPLAY_TYPE_TIMELINE)}
          title="Show Timeline Display"
        >
          <Octicon icon={Calendar} ariaLabel="Show Timeline Display" />
        </button>
      )}
      {showDisplayButtons && (
        <button
          className={[
            "Tab--Display",
            selectedTab === DISPLAY_TYPE_CHART ? "is-active" : ""
          ].join(" ")}
          onClick={() => handleTabClick(DISPLAY_TYPE_CHART)}
          title="Show Graph Display"
        >
          <Octicon icon={Pulse} ariaLabel="Show Graph Display" />
        </button>
      )}
      <button
        className={selectedTab === VIEW_DETAIL_DAY ? "is-active" : ""}
        onClick={() => handleTabClick(VIEW_DETAIL_DAY)}
        title="Selected Day Details"
      >
        <Octicon icon={Starmemory} ariaLabel="Selected Day Details" />
      </button>
      <button
        className={selectedTab === VIEW_EDIT_TRACKERS ? "is-active" : ""}
        onClick={() => handleTabClick(VIEW_EDIT_TRACKERS)}
        title="Edit Trackers & Categories"
      >
        <Octicon icon={Pencil} ariaLabel="Edit Trackers" />
      </button>
      <button
        className={selectedTab === VIEW_ACCOUNT_SETTINGS ? "is-active" : ""}
        onClick={() => handleTabClick(VIEW_ACCOUNT_SETTINGS)}
        title="Change Settings"
      >
        <Octicon icon={Tools} ariaLabel="Change Settings" />
      </button>
    </div>
  );
};

Tabs.propTypes = {
  selectedTab: PropTypes.string.isRequired,
  handleTabClick: PropTypes.func.isRequired,
  showDisplayButtons: PropTypes.bool.isRequired
};

export default Tabs;
