import React from "react";
import PropTypes from "prop-types";

const DayTotal = ({ items, trackers, filteredTrackers }) => {
  if (items == null || trackers == null) {
    return null;
  }

  const total = items.reduce((accumulator, trackerId) => {
    if (!trackers[trackerId]) return accumulator;
    return filteredTrackers.includes(trackerId) ? accumulator : accumulator + 1;
  }, 0);

  if (total === 0) return null;

  let pointSize = "Number--small";
  if (total > 30) {
    pointSize = "Number--huge";
  } else if (total > 20) {
    pointSize = "Number--large";
  } else if (total > 10) {
    pointSize = "Number--medium";
  }

  return <div className={`Number ${pointSize}`}>{total}</div>;
};

DayTotal.propTypes = {
  items: PropTypes.array,
  filteredTrackers: PropTypes.array,
  trackers: PropTypes.object
};

export default DayTotal;
