import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { firestore, Timestamp } from "../../../lib/firebase";
import { DEFAULT_DATASET } from "../../../lib/constants";
import Octicon, { Trashcan, Plus } from "@primer/octicons-react";
import modalStyles from "../../../lib/modalStyles";
import "./css/DataSetsModal.css";

Modal.setAppElement("#root");

const DataSetsModal = ({
  user,
  modalIsOpen,
  closeModal,
  selectedDataSet,
  handleDataSetChange
}) => {
  const [dataSets, setDataSets] = useState({});

  useEffect(() => {
    firestore
      .collection("users")
      .doc(user.uid)
      .collection("dataSets")
      .orderBy("dateAdded")
      .get()
      .then(snapShot => {
        let dataSets = {};

        if (snapShot.size >= 1) {
          snapShot.forEach(doc => {
            dataSets[doc.id] = doc.data();
          });
        }

        setDataSets(dataSets);
      });
  }, [user.uid]);

  const onClose = () => {
    closeModal();
  };

  const changeDataSetLabel = (dataSetId, label) => {
    const oldDataSets = { ...dataSets };
    let newDataSets = { ...dataSets };

    if (newDataSets[dataSetId]) {
      newDataSets[dataSetId].label = label;

      setDataSets(newDataSets);

      firestore
        .collection("users")
        .doc(user.uid)
        .collection("dataSets")
        .doc(dataSetId)
        .set({ label: label }, { merge: true })
        .catch(() => {
          setDataSets(oldDataSets);
        });
    }
  };

  const addDataSet = () => {
    const label = "New";
    const dateAdded = Timestamp.now();

    firestore
      .collection("users")
      .doc(user.uid)
      .collection("dataSets")
      .add({ label: label, dateAdded: dateAdded })
      .then(docRef => {
        let newDataSets = { ...dataSets };
        newDataSets[docRef.id] = { label: label, dateAdded: dateAdded };
        setDataSets(newDataSets);
      });
  };

  const deleteDataSet = dataSetId => {
    if (
      dataSetId &&
      dataSetId !== DEFAULT_DATASET &&
      window.confirm(
        "All data under this data set (including daily entries, trackers, categories) will also be deleted. Are you sure you want to delete this data set?"
      )
    ) {
      if (dataSetId === selectedDataSet) {
        handleDataSetChange(DEFAULT_DATASET);
      }

      let oldDataSets = { ...dataSets };
      let newDataSets = { ...dataSets };
      delete newDataSets[dataSetId];
      setDataSets(newDataSets);

      firestore
        .collection("users")
        .doc(user.uid)
        .collection("dataSets")
        .doc(dataSetId)
        .delete()
        .catch(() => {
          setDataSets(oldDataSets);
        });
    }
  };

  let sortedDataSets = Object.keys(dataSets)
    .map(key => ({
      id: key,
      label: dataSets[key].label,
      dateAdded: dataSets[key].dateAdded
    }))
    .sort((a, b) => {
      return a.dateAdded.seconds > b.dateAdded.seconds;
    });

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={onClose}
      contentLabel="Data Sets Modal"
      style={modalStyles}
    >
      <div className="DataSetsModal modal-content">
        <div className="modal-header">
          <h2>Data Sets</h2>
          <div>
            <button className="modal-close" onClick={onClose}>
              Close
            </button>
          </div>
        </div>
        <div>
          {
            <ul>
              {sortedDataSets.map(dataSet => (
                <li
                  key={dataSet.id}
                  className={
                    dataSet.id === DEFAULT_DATASET ? "default-dataset" : ""
                  }
                >
                  <input
                    type="text"
                    value={dataSet.label}
                    onChange={e => {
                      changeDataSetLabel(dataSet.id, e.target.value);
                    }}
                  />
                  {dataSet.id !== DEFAULT_DATASET && (
                    <button
                      className="sm-outliner"
                      onClick={() => deleteDataSet(dataSet.id)}
                      title="Delete Data Set"
                    >
                      <Octicon icon={Trashcan} ariaLabel="Delete Data Set" />
                    </button>
                  )}
                </li>
              ))}
            </ul>
          }
          <button
            className="DataSetModal__AddDataSet sm-outliner"
            onClick={() => {
              addDataSet();
            }}
          >
            <Octicon icon={Plus} /> Add Data Set
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default DataSetsModal;
