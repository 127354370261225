import React, { useState } from "react";
import Octicon, {
  SignOut,
  Server,
  Key,
  Verified,
  Mail,
  MailRead,
  Person
} from "@primer/octicons-react";
import { fireauth } from "../../../lib/firebase";
import DataSetsModal from "./DataSetsModal";
import LoaderSmall from "../../LoaderSmall";
import VerifyEmailModal from "./VerifyEmailModal";
import ChangePasswordModal from "./ChangePasswordModal";
import SendFeedbackModal from "./SendFeedbackModal";
import "./css/Settings.css";

const Settings = ({ user, userData, selectedDataSet, handleDataSetChange }) => {
  const [dataSetsModalIsOpen, setDataSetsModalIsOpen] = useState(false);
  const [verifyEmailModalIsOpen, setVerifyEmailModalIsOpen] = useState(false);
  const [changePasswordModalIsOpen, setChangePasswordModalIsOpen] = useState(
    false
  );
  const [feedbackModalIsOpen, setFeedbackModalIsOpen] = useState(false);
  const [sendingVerificationEmail, setSendingVerificationEmail] = useState(
    false
  );

  const sendEmailVerification = () => {
    setSendingVerificationEmail(true);
    fireauth.currentUser.sendEmailVerification().then(() => {
      setSendingVerificationEmail(false);
      setVerifyEmailModalIsOpen(true);
    });
  };

  return (
    <div className="Settings">
      <h2>Account Details</h2>
      <ul className="Settings__Details">
        <li>
          <div className="Settings__Label">
            <span>
              <Octicon icon={Mail} />
            </span>
            <span>Email</span>
          </div>
          <div className="Settings__EmailVerify Settings__Value">
            <div title={user.email}>{user.email}</div>
            <div>
              {user.emailVerified && (
                <span className="verified" title="Email Address Verified">
                  <Octicon icon={Verified} />
                </span>
              )}
              {!user.emailVerified && sendingVerificationEmail && (
                <LoaderSmall message="Sending…" />
              )}
              {!user.emailVerified && !sendingVerificationEmail && (
                <button onClick={sendEmailVerification}>Verify</button>
              )}
            </div>
          </div>
        </li>
        <li>
          <div className="Settings__Label">
            <span>
              <Octicon icon={Person} />
            </span>
            <span>Account Type</span>
          </div>
          <div className="Settings__Value">
            {userData.premium ? "Premium" : "Free"}
          </div>
        </li>
      </ul>
      <h2>Actions</h2>
      <ul className="Settings__Actions">
        <li>
          <button
            className="sm-outliner"
            onClick={() => setDataSetsModalIsOpen(true)}
          >
            <div>
              <Octicon icon={Server} />
            </div>
            <div>Manage Datasets</div>
          </button>
        </li>
        <li>
          <button
            className="sm-outliner"
            onClick={() => setChangePasswordModalIsOpen(true)}
          >
            <div>
              <Octicon icon={Key} />
            </div>
            <div>Change Password</div>
          </button>
        </li>
        <li>
          <button
            className="sm-outliner"
            onClick={() => setFeedbackModalIsOpen(true)}
          >
            <div>
              <Octicon icon={MailRead} />
            </div>
            <div>Send Feedback</div>
          </button>
        </li>
        <li>
          <button className="sm-outliner" onClick={() => fireauth.signOut()}>
            <div>
              <Octicon icon={SignOut} />
            </div>
            <div>Sign Out</div>
          </button>
        </li>
      </ul>
      <DataSetsModal
        user={user}
        selectedDataSet={selectedDataSet}
        handleDataSetChange={handleDataSetChange}
        modalIsOpen={dataSetsModalIsOpen}
        closeModal={() => setDataSetsModalIsOpen(false)}
      />
      <VerifyEmailModal
        modalIsOpen={verifyEmailModalIsOpen}
        closeModal={() => setVerifyEmailModalIsOpen(false)}
      />
      <ChangePasswordModal
        modalIsOpen={changePasswordModalIsOpen}
        closeModal={() => setChangePasswordModalIsOpen(false)}
      />
      <SendFeedbackModal
        user={user}
        modalIsOpen={feedbackModalIsOpen}
        closeModal={() => setFeedbackModalIsOpen(false)}
      />
    </div>
  );
};

export default Settings;
