import React from "react";
import PropTypes from "prop-types";
import Octicon, { Star } from "@primer/octicons-react";

const PointTotal = ({ items, trackers }) => {
  let total = 0;

  if (items && trackers) {
    total = items.reduce((acc, trackerId) => {
      return trackers[trackerId] ? acc + trackers[trackerId].points : acc;
    }, 0);
  }

  return (
    <span className="PointTotal" title="Daily Score">
      <Octicon icon={Star} verticalAlign="top" />
      {total}
    </span>
  );
};

PointTotal.propTypes = {
  items: PropTypes.array,
  trackers: PropTypes.object
};

export default PointTotal;
