import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Modal from "react-modal";
import modalStyles from "../lib/modalStyles";
import { fireauth } from "../lib/firebase";
import LoaderMedium from "./LoaderMedium";
import "./css/ForgotPassword.css";

Modal.setAppElement("#root");

const ForgotPassword = ({ modalIsOpen, closeModal }) => {
  const [sent, setSent] = useState(false);
  const [sending, setSending] = useState(false);
  const [error, setError] = useState(null);

  const onClose = () => {
    setError(null);
    setSent(false);
    setSending(false);
    closeModal();
  };

  const sendPasswordReset = emailAddress => {
    setSending(true);

    fireauth
      .sendPasswordResetEmail(emailAddress)
      .then(() => {
        setSending(false);
        setSent(true);
        setError(null);
      })
      .catch(error => {
        // auth/invalid-email
        // auth/user-not-found
        setSending(false);
        setError(error.message);
      });
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={onClose}
      contentLabel="Example Modal"
      style={modalStyles}
    >
      <div className="ForgotPassword modal-content modal-form">
        <div className="modal-header">
          <h2>Reset Password</h2>
          <div>
            <button className="modal-close" onClick={onClose}>
              Close
            </button>
          </div>
        </div>
        {!sent && !sending && (
          <Formik
            initialValues={{ email: "", password: "" }}
            validate={values => {
              const errors = {};

              if (!values.email) {
                errors.email = "Please enter your email address";
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = "Invalid email address";
              }

              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              sendPasswordReset(values.email);
              setSubmitting(false);
            }}
          >
            {({ errors, touched, isSubmitting }) => (
              <>
                <Form>
                  <ul>
                    <li
                      className={
                        errors.email && touched.email ? "field-error" : ""
                      }
                    >
                      <label htmlFor="email">Email</label>
                      <Field type="text" name="email" autoComplete="email" />
                      <ErrorMessage
                        name="email"
                        className="modal-form__error"
                        component="div"
                      />
                      {error && (
                        <div className="modal-form__error">{error}</div>
                      )}
                    </li>
                    <li>
                      <button type="submit" disabled={isSubmitting}>
                        Send Reset Email
                      </button>
                    </li>
                  </ul>
                </Form>
              </>
            )}
          </Formik>
        )}
        {sending && (
          <div className="ForgotPassword__Sending">
            <LoaderMedium message="Verifying…" />
          </div>
        )}
        {sent && (
          <div className="ForgotPassword__Sent">
            <p>We've sent an email with a link to reset your password.</p>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default ForgotPassword;
