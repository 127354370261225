import React from "react";
import "./css/CategoryList.css";
import Category from "./Category";

const CategoryList = ({
  items,
  trackers,
  trackersSort,
  categories,
  categoriesSort,
  collapsedCategories,
  filteredTrackers,
  toggleCategoryFiltered,
  toggleCategoryCollapse,
  toggleTrackerFiltered,
  addItemToEntry,
  subtractItemFromEntry
}) => {
  if (categoriesSort == null) categoriesSort = [];

  const trackerListByCategory = categoriesSort.map(categoryId => {
    let category = {};

    if (categories[categoryId]) {
      category = categories[categoryId];
    } else {
      return null;
    }

    if (
      typeof trackersSort[categoryId] !== "undefined" &&
      trackersSort[categoryId].length < 1
    ) {
      return null;
    }

    return (
      <li key={categoryId}>
        <Category
          categoryId={categoryId}
          category={category}
          collapsed={collapsedCategories.includes(categoryId)}
          trackers={trackers}
          trackersSort={trackersSort[categoryId]}
          //
          filteredTrackers={filteredTrackers}
          toggleCategoryFiltered={toggleCategoryFiltered}
          toggleCategoryCollapse={toggleCategoryCollapse}
          //
          items={items}
          toggleTrackerFiltered={toggleTrackerFiltered}
          addItemToEntry={addItemToEntry}
          subtractItemFromEntry={subtractItemFromEntry}
        />
      </li>
    );
  });

  return <ul className="CategoryList">{trackerListByCategory}</ul>;
};

// We need to re-render if any of these props change but not when the add/subtract
// items from entries function change, which they do when DetailDay re-renders
function areEqual(prevProps, nextProps) {
  if (prevProps.categories !== nextProps.categories) {
    return false;
  }

  if (prevProps.categoriesSort !== nextProps.categoriesSort) {
    return false;
  }

  if (prevProps.collapsedCategories !== nextProps.collapsedCategories) {
    return false;
  }

  if (prevProps.filteredTrackers !== nextProps.filteredTrackers) {
    return false;
  }

  if (prevProps.items !== nextProps.items) {
    return false;
  }

  if (prevProps.trackers !== nextProps.trackers) {
    return false;
  }

  if (prevProps.trackersSort !== nextProps.trackersSort) {
    return false;
  }

  return true;
}

export default React.memo(CategoryList, areEqual);
