import React, { useState } from "react";
import PropTypes from "prop-types";
import { Droppable } from "react-beautiful-dnd";
import Octicon, { Plus } from "@primer/octicons-react";
import EditableCategory from "./EditableCategory";
import TrackerModal from "./TrackerModal";
import LoaderSmall from "../../LoaderSmall";
import "./css/Edit.css";
import "./css/TrackerModal.css";

const Edit = ({
  usedEmojisData,
  trackers,
  trackersSort,
  categories,
  categoriesSort,
  //
  handleAddNewTracker,
  handleOnChangeTracker,
  updateTrackerDetails,
  handleConfirmDeleteTracker,
  addingTrackers,
  //
  handleAddNewCategory,
  handleChangeCategoryLabel,
  handleConfirmDeleteCategory,
  addingCategory
}) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalTrackerId, setModalTrackerId] = useState(null);

  const openTrackerModal = trackerId => {
    setModalTrackerId(trackerId);
    setIsOpen(true);
  };

  const closeTrackerModal = () => {
    setModalTrackerId(null);
    setIsOpen(false);
  };

  if (categoriesSort == null) categoriesSort = [];

  return (
    <div className="Edit">
      {modalTrackerId && (
        <TrackerModal
          modalIsOpen={modalIsOpen}
          closeTrackerModal={closeTrackerModal}
          tracker={trackers[modalTrackerId]}
          trackerId={modalTrackerId}
          updateTrackerDetails={updateTrackerDetails}
          usedEmojisData={usedEmojisData}
        />
      )}
      <div className="EditableCategoryList">
        <Droppable droppableId={`categories`} type="CATEGORY">
          {(provided, snapshot) => (
            <ul ref={provided.innerRef} {...provided.droppableProps}>
              {categoriesSort.map((categoryId, index) => {
                if (!categories[categoryId]) {
                  return null;
                }

                return (
                  <EditableCategory
                    key={categoryId}
                    index={index}
                    categoryId={categoryId}
                    category={categories[categoryId]}
                    handleConfirmDeleteCategory={handleConfirmDeleteCategory}
                    handleChangeCategoryLabel={handleChangeCategoryLabel}
                    trackers={trackers}
                    trackersSort={trackersSort}
                    handleOnChangeTracker={handleOnChangeTracker}
                    handleConfirmDeleteTracker={handleConfirmDeleteTracker}
                    handleAddNewTracker={handleAddNewTracker}
                    addingTrackers={addingTrackers}
                    openTrackerModal={openTrackerModal}
                  />
                );
              })}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
        <div className="EditableCategoryList__Controls">
          {addingCategory ? (
            <LoaderSmall message="Adding Category…" />
          ) : (
            <button
              className="button sm-outliner"
              onClick={handleAddNewCategory}
            >
              <span>
                <Octicon icon={Plus} />
              </span>
              <span>Add Category</span>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

Edit.propTypes = {
  trackers: PropTypes.object
};

export default Edit;
