import React, { useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import PropTypes from "prop-types";
import moment from "moment";
import Octicon, { Terminal, ChevronUp } from "@primer/octicons-react";
import { firestore } from "../../../lib/firebase";
import CategoryList from "./CategoryList";
import SelectedDay from "./SelectedDay";
import { DATE_FORMAT_SHORT, DATE_FORMAT_DAY } from "../../../lib/constants.js";
import "./css/DetailDay.css";

const DetailDay = ({
  date,
  user,
  selectedDataSet,
  setSelectedDay,
  trackers,
  trackersSort,
  categories,
  categoriesSort,
  collapsedCategories,
  filteredTrackers,
  toggleCategoryFiltered,
  toggleCategoryCollapse,
  toggleTrackerFiltered
}) => {
  const [items, setItems] = useState([]);
  const [notes, setNotes] = useState("");
  const [notesOpen, setNotesOpen] = useState(true);
  const [debouncedUpdateNotes] = useDebouncedCallback((notes, date) => {
    updateNotes(notes, date);
  }, 300);

  useEffect(() => {
    const notesOpen = localStorage.getItem(`${user.uid}:dd:no`);
    if (notesOpen) {
      setNotesOpen(JSON.parse(notesOpen));
    }
  }, [user.uid]);

  useEffect(() => {
    let unsubEntrySnapShot = null;
    const docRef = firestore
      .collection("users")
      .doc(user.uid)
      .collection("dataSets")
      .doc(selectedDataSet)
      .collection("entries")
      .doc(date.format(DATE_FORMAT_SHORT));

    docRef.get().then(doc => {
      if (doc.exists) {
        const data = doc.data();

        if (data && data.days) {
          const day = data.days[date.format(DATE_FORMAT_DAY)];

          if (day) {
            setItems(day.items || []);
            setNotes(day.notes || "");
          } else {
            setItems([]);
            setNotes("");
          }
        } else {
          setItems([]);
          setNotes("");
        }
      } else {
        doc.ref.set({ days: {} });
        setItems([]);
        setNotes("");
      }

      unsubEntrySnapShot = docRef.onSnapshot(doc => {
        const source = doc.metadata.hasPendingWrites ? "Local" : "Server";
        const data = doc.data();

        if (data && source === "Server" && data && data.days) {
          const day = data.days[date.format(DATE_FORMAT_DAY)];

          if (day) {
            setItems(day.items || []);
            setNotes(day.notes || "");
          } else {
            setItems([]);
            setNotes("");
          }
        }
      });
    });

    return () => {
      if (unsubEntrySnapShot) unsubEntrySnapShot();
    };
  }, [date, selectedDataSet, user.uid]);

  const updateNotes = (notes, date) => {
    firestore
      .collection("users")
      .doc(user.uid)
      .collection("dataSets")
      .doc(selectedDataSet)
      .collection("entries")
      .doc(date.format(DATE_FORMAT_SHORT))
      .update({
        [`days.${moment(date).format("DD")}.notes`]: notes
      })
      .catch(() => {});
  };

  const addItemToEntry = trackerId => {
    if (trackerId) {
      const oldItems = [...items];
      const newItems = items ? [...items, trackerId] : [trackerId];

      setItems(newItems);

      firestore
        .collection("users")
        .doc(user.uid)
        .collection("dataSets")
        .doc(selectedDataSet)
        .collection("entries")
        .doc(date.format(DATE_FORMAT_SHORT))
        .update({
          [`days.${moment(date).format("DD")}.items`]: newItems
        })
        .catch(() => {
          setItems(oldItems);
        });
    }
  };

  const subtractItemFromEntry = trackerId => {
    if (trackerId) {
      const oldItems = [...items];
      let newItems = [...items];

      if (newItems) {
        const index = newItems.indexOf(trackerId);
        if (index > -1) {
          newItems.splice(index, 1);
        }

        setItems(newItems);

        firestore
          .collection("users")
          .doc(user.uid)
          .collection("dataSets")
          .doc(selectedDataSet)
          .collection("entries")
          .doc(date.format(DATE_FORMAT_SHORT))
          .update({
            [`days.${moment(date).format("DD")}.items`]: newItems
          })
          .catch(() => {
            setItems(oldItems);
          });
      }
    }
  };

  const entryNoteChange = (notes, date) => {
    debouncedUpdateNotes(notes, date);
    setNotes(notes);
  };

  const handleOnNotesOpenToggle = notesOpen => {
    setNotesOpen(notesOpen);
    localStorage.setItem(`${user.uid}:dd:no`, notesOpen);
  };

  return (
    <div className="DetailDay">
      <div className="DetailDay__Header">
        <SelectedDay
          date={date}
          setSelectedDay={setSelectedDay}
          items={items}
          trackers={trackers}
        />
      </div>
      {trackers && (
        <CategoryList
          items={items}
          trackers={trackers}
          trackersSort={trackersSort}
          categories={categories}
          categoriesSort={categoriesSort}
          collapsedCategories={collapsedCategories}
          filteredTrackers={filteredTrackers}
          toggleCategoryCollapse={toggleCategoryCollapse}
          toggleCategoryFiltered={toggleCategoryFiltered}
          toggleTrackerFiltered={toggleTrackerFiltered}
          addItemToEntry={addItemToEntry}
          subtractItemFromEntry={subtractItemFromEntry}
        />
      )}
      <div
        className={[
          "DetailDay__Notes",
          notesOpen ? "DetailDay__Notes--open" : ""
        ].join(" ")}
      >
        <div className="DetailDay__NotesHeader">
          <label htmlFor="notes-field">
            <span>
              <Octicon icon={Terminal} verticalAlign="middle" />
            </span>
            <span>Notes</span>
          </label>
          <button
            className="sm-outliner"
            onClick={() => handleOnNotesOpenToggle(!notesOpen)}
            title={notesOpen ? "Close Notes" : "Open Notes"}
          >
            <Octicon icon={ChevronUp} verticalAlign="middle" />
          </button>
        </div>
        {notesOpen && (
          <div className="DetailDay__NotesText">
            <textarea
              id="notes-field"
              value={notes ? notes : ""}
              onChange={e => entryNoteChange(e.target.value, date)}
            />
          </div>
        )}
      </div>
    </div>
  );
};

DetailDay.propTypes = {
  date: PropTypes.object.isRequired,
  trackers: PropTypes.object,
  selectedDataSet: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  setSelectedDay: PropTypes.func.isRequired,
  trackersSort: PropTypes.object,
  categories: PropTypes.object,
  categoriesSort: PropTypes.array,
  collapsedCategories: PropTypes.array,
  filteredTrackers: PropTypes.array,
  toggleCategoryFiltered: PropTypes.func.isRequired,
  toggleCategoryCollapse: PropTypes.func.isRequired,
  toggleTrackerFiltered: PropTypes.func.isRequired
};

export default DetailDay;
