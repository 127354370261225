import React from "react";
import PropTypes from "prop-types";
import Emoji from "../../../Emoji";

const DayEmojis = ({
  items,
  trackers,
  flattenedTrackersSort,
  filteredTrackers
}) => {
  if (items == null || trackers == null) {
    return null;
  }

  if (flattenedTrackersSort) {
    items.sort((a, b) => {
      return (
        flattenedTrackersSort.indexOf(a) > flattenedTrackersSort.indexOf(b)
      );
    });
  }

  let itemList = items.map((trackerId, i) => {
    if (trackers[trackerId]) {
      if (filteredTrackers.includes(trackerId)) return null;
      return (
        <li
          className="Item"
          key={trackers[trackerId].label + i}
          style={{ backgroundColor: trackers[trackerId].colour }}
        >
          <Emoji
            emoji={trackers[trackerId].emoji}
            label={trackers[trackerId].label}
          />
        </li>
      );
    } else {
      return null;
    }
  });

  return <ul className="Items">{itemList}</ul>;
};

DayEmojis.propTypes = {
  items: PropTypes.array,
  filteredTrackers: PropTypes.array,
  trackers: PropTypes.object
};

export default DayEmojis;
