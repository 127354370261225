import React from "react";
import "./css/Category.css";
import PropTypes from "prop-types";
import { Eye, EyeClosed, EyeUnknown } from "../../icons/Custom";
import Octicon, { ChevronUp } from "@primer/octicons-react";
import TrackerList from "./TrackerList";

const ITEM_CATEGORY_STATUS = Object.freeze({
  FILTERED: "filtered",
  UNFILTERED: "unfiltered",
  MIXED: "mixed"
});

const getCategoryFilterStatus = (trackersSort, filteredTrackers) => {
  let filterCount = 0;

  trackersSort.forEach(trackerId => {
    if (filteredTrackers.includes(trackerId)) {
      filterCount++;
    }
  });

  if (filterCount === 0) {
    return ITEM_CATEGORY_STATUS.UNFILTERED;
  }

  if (filterCount === trackersSort.length) {
    return ITEM_CATEGORY_STATUS.FILTERED;
  }

  return ITEM_CATEGORY_STATUS.MIXED;
};

const Category = ({
  categoryId,
  category,
  collapsed,
  trackers,
  trackersSort,
  //
  filteredTrackers,
  toggleCategoryFiltered,
  toggleCategoryCollapse,
  //
  items,
  toggleTrackerFiltered,
  addItemToEntry,
  subtractItemFromEntry
}) => {
  const categoryFilterStatus = getCategoryFilterStatus(
    trackersSort,
    filteredTrackers
  );

  return (
    <div
      className={[
        "Category",
        categoryFilterStatus === ITEM_CATEGORY_STATUS.FILTERED
          ? "Category--filtered"
          : "",
        collapsed ? "Category--collapsed" : ""
      ].join(" ")}
    >
      <div className="Category__Header">
        <div className="Category__Info">
          <button
            className="sm-outliner"
            onClick={() => toggleCategoryFiltered(categoryId)}
            title="Toggle Category Filtering"
          >
            {categoryFilterStatus === ITEM_CATEGORY_STATUS.UNFILTERED && (
              <Octicon icon={Eye} ariaLabel="Trackers Not Filtered" />
            )}
            {categoryFilterStatus === ITEM_CATEGORY_STATUS.FILTERED && (
              <Octicon icon={EyeClosed} ariaLabel="Trackers Filtered" />
            )}
            {categoryFilterStatus === ITEM_CATEGORY_STATUS.MIXED && (
              <Octicon icon={EyeUnknown} ariaLabel="Some Trackers Filtered" />
            )}
          </button>
          <div className="Category__Label">
            <span>{category.label}</span>
          </div>
        </div>
        <div className="Category__Controls">
          <button
            className="sm-outliner"
            onClick={() => toggleCategoryCollapse(categoryId)}
            title={collapsed ? "Expand Category" : "Collapse Category"}
          >
            <Octicon icon={ChevronUp} />
          </button>
        </div>
      </div>
      {!collapsed && (
        <TrackerList
          items={items}
          trackers={trackers}
          trackersSort={trackersSort}
          filteredTrackers={filteredTrackers}
          toggleTrackerFiltered={toggleTrackerFiltered}
          addItemToEntry={addItemToEntry}
          subtractItemFromEntry={subtractItemFromEntry}
        />
      )}
    </div>
  );
};

Category.propTypes = {
  categoryId: PropTypes.string
};

export default Category;
