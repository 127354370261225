import React from "react";
import moment from "moment";
import Octicon, { ArrowLeft, ArrowRight } from "@primer/octicons-react";
import {
  DayOptions,
  MonthOptions,
  YearOptions
} from "../../DateControlsOptions.js";
import PointTotal from "../../PointTotal";
import "./css/SelectedDay.css";

const SelectedDay = ({ date, setSelectedDay, items, trackers }) => (
  <div
    className="SelectedDay"
    aria-label="Selected Day"
    title={date.format("MMMM Do, YYYY")}
    role="group"
    aria-labelledby="SelectedDay__Label"
  >
    <div className="SelectedDay__Label" id="SelectedDay__Label">
      <div>Selected Day</div>
      <div>
        <PointTotal items={items} trackers={trackers} />
      </div>
    </div>
    <div className="SelectedDay__Controls">
      <button
        className="sm-outliner"
        onClick={() => {
          const newDate = moment(date).subtract(1, "days");
          setSelectedDay(newDate);
        }}
      >
        <Octicon
          icon={ArrowLeft}
          verticalAlign="middle"
          ariaLabel="Move back one day"
        />
      </button>
      <select
        className="sm-outliner"
        aria-label="Month"
        value={date.month()}
        onChange={e => {
          const newDate = moment(date).month(e.target.value);
          setSelectedDay(newDate);
        }}
      >
        <MonthOptions endDate={date} />
      </select>
      <select
        className="sm-outliner"
        aria-label="Day"
        value={date.date()}
        onChange={e => {
          const newDate = moment(date).date(e.target.value);
          setSelectedDay(newDate);
        }}
      >
        <DayOptions endDate={date} disableFuture />
      </select>
      <select
        className="sm-outliner"
        aria-label="Year"
        value={date.year()}
        onChange={e => {
          const newDate = moment(date).year(e.target.value);
          setSelectedDay(newDate);
        }}
      >
        <YearOptions endDate={moment()} />
      </select>
      <button
        className="sm-outliner"
        onClick={() => {
          const newDate = moment(date).add(1, "days");
          setSelectedDay(newDate);
        }}
        disabled={moment(date).add(1, "day") >= moment()}
      >
        <Octicon
          icon={ArrowRight}
          verticalAlign="middle"
          ariaLabel="Move forward one day"
        />
      </button>
    </div>
  </div>
);

export default React.memo(SelectedDay);
