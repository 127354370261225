import React from "react";

function Eye() {
  return React.createElement("path", {
    fillRule: "evenodd",
    d:
      "M8 3c-3-.03-6 1.561-8 4.87 4 6.85 12 6.836 16-.015C14 4.681 11 3.03 8 3zm0 1.994c2.25.02 4.5 1.012 6 2.92-3 4.11-9 4.118-12 0 1.5-1.984 3.75-2.939 6-2.92zM8 5.5A2.5 2.5 0 005.5 8 2.5 2.5 0 008 10.5 2.5 2.5 0 0010.5 8 2.5 2.5 0 008 5.5zm-.7.9a.8.8 0 01.8.8.8.8 0 01-.8.8.8.8 0 01-.8-.8.8.8 0 01.8-.8z"
  });
}

Eye.size = [16, 16];

function EyeClosed() {
  return React.createElement("path", {
    fillRule: "evenodd",
    d:
      "M.086 8c3.996 6.66 11.817 6.651 15.818 0H13.93A7.368 7.368 0 012.07 8H.086z"
  });
}

EyeClosed.size = [16, 16];

function EyeUnknown() {
  return React.createElement("path", {
    fillRule: "evenodd",
    d:
      "M7 2v1.057c-2.655.295-5.223 1.873-7 4.812 1.778 3.045 4.345 4.72 7 5.053V14h2v-1.074c2.608-.339 5.131-1.98 6.904-4.926H13.93A7.398 7.398 0 019 10.92V2H7zm0 3.059v.652A2.5 2.5 0 005.5 8 2.5 2.5 0 007 10.29v.636c-1.901-.258-3.723-1.259-5-3.012C3.277 6.224 5.099 5.29 7 5.06z"
  });
}

EyeUnknown.size = [16, 16];

function Move() {
  return React.createElement("path", {
    fillRule: "evenodd",
    d:
      "M7.924 0L5 4h2v3H4V5L0 8.076 4 11V9h3v3H5l2.924 4L11 12H9V9h3v2l4-3.076L12 5v2H9V4h2L7.924 0z"
  });
}

Move.size = [16, 16];

function Starmemory() {
  return React.createElement("path", {
    fillRule: "evenodd",
    d:
      "M9.956 2.872c1.622-.881 3.649-2.505 4.726-1.612 1.096.907-.995 3.822-.995 3.822-1.389-4.88-4.739.394-5.175-.134-2.46-2.979-6.465 2.398-4.113 5.34 1.432 1.79 5.055-7.547 7.695-5.8.885.585 2.401 4.135.208 6.913-1.908 2.418-4.85 2.324-6.307 1.741-1.4.955-3.859 2.432-4.739 1.625-.587-.539-.141-1.795 1.105-3.713.322 4.227 3.63.71 4.08.878 1.116.357 3.5-1.557 2.93-4.208-.057-.943-3.924 4.979-5.466 3.856-.831-.606-2.476-4.026-.293-6.86 1.89-2.455 4.603-2.536 6.344-1.848z"
  });
}

Starmemory.size = [16, 16];

function Streak() {
  return React.createElement("path", {
    fillRule: "evenodd",
    d:
      "M1 1v14h2V1H1zm3 0v2h2V1H4zm3 0v2h2V1H7zm3 0v2h2V1h-2zM4 4v2h2V4H4zm9 0v2h2V4h-2zM7 7v2h2V7H7zm3 0v2h2V7h-2zm-6 3v2h2v-2H4zm3 0v2h2v-2H7zm3 0v2h2v-2h-2zm3 0v2h2v-2h-2zm-9 3v2h2v-2H4zm6 0v2h2v-2h-2z"
  });
}

Streak.size = [16, 16];

export { Eye, EyeClosed, EyeUnknown, Move, Starmemory, Streak };
