import React from "react";
import PropTypes from "prop-types";

const DayTinySquares = ({
  items,
  trackers,
  flattenedTrackersSort,
  filteredTrackers
}) => {
  if (items == null || trackers == null) {
    return null;
  }

  if (flattenedTrackersSort) {
    items.sort((a, b) => {
      return (
        flattenedTrackersSort.indexOf(a) > flattenedTrackersSort.indexOf(b)
      );
    });
  }

  let itemList = items.map((trackerId, i) => {
    if (filteredTrackers.includes(trackerId)) return null;

    return trackers[trackerId] ? (
      <li
        className="Item"
        title={trackers[trackerId].label}
        key={trackers[trackerId].label + i}
        style={{ backgroundColor: trackers[trackerId].colour }}
      />
    ) : null;
  });

  return <ul className="Items">{itemList}</ul>;
};

DayTinySquares.propTypes = {
  items: PropTypes.array,
  filteredTrackers: PropTypes.array,
  trackers: PropTypes.object
};

export default DayTinySquares;
