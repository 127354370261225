import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as SfIcon } from "../images/starmemory-icon-V2.svg";
import "./css/LoaderSmall.css";

const LoaderSmall = ({ message }) => (
  <div className="LoaderSmall">
    <div>
      <SfIcon />
    </div>
    <div>{message}</div>
  </div>
);

LoaderSmall.defaultProps = {
  message: ""
};

LoaderSmall.propTypes = {
  message: PropTypes.string
};

export default LoaderSmall;
