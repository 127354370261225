import React, { useState } from "react";
import PropTypes from "prop-types";
import { Draggable } from "react-beautiful-dnd";
import { Move } from "../../icons/Custom";
import Octicon, { Plus, Trashcan } from "@primer/octicons-react";
import EditableTrackerList from "./EditableTrackerList";
import "./css/EditableCategory.css";
import LoaderSmall from "../../LoaderSmall";

const EditableCategory = ({
  index,
  categoryId,
  category,
  handleConfirmDeleteCategory,
  handleChangeCategoryLabel,
  trackers,
  trackersSort,
  handleOnChangeTracker,
  handleConfirmDeleteTracker,
  handleAddNewTracker,
  addingTrackers,
  openTrackerModal
}) => {
  const [label, setLabel] = useState(category.label);

  return (
    <Draggable draggableId={categoryId} index={index} key={categoryId}>
      {(provided, snapshot) => (
        <li
          className="EditableCategory"
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          <div className="EditableCategory__Header">
            <div className="EditableCategory__Controls">
              <button
                className="sm-outliner"
                title="Move Category"
                {...provided.dragHandleProps}
              >
                <Octicon icon={Move} />
              </button>
            </div>
            <div className="EditableCategory__Info">
              <input
                type="text"
                onBlur={e =>
                  handleChangeCategoryLabel(e.target.value, categoryId)
                }
                onChange={e => setLabel(e.target.value)}
                value={label}
              />
            </div>
            <div className="EditableCategory__Controls">
              <button
                className="sm-outliner"
                onClick={() => handleConfirmDeleteCategory(categoryId)}
                title="Delete Category"
              >
                <Octicon icon={Trashcan} ariaLabel="Delete Category" />
              </button>
            </div>
          </div>
          <EditableTrackerList
            categoryId={categoryId}
            trackers={trackers}
            trackersSort={trackersSort[categoryId]}
            handleOnChangeTracker={handleOnChangeTracker}
            handleConfirmDeleteTracker={handleConfirmDeleteTracker}
            openTrackerModal={openTrackerModal}
          />
          {trackersSort[categoryId] && trackersSort[categoryId].length < 1 && (
            <div className="EditableCategory__Empty">empty</div>
          )}
          <div className="EditableCategory__AddTracker">
            {addingTrackers.includes(categoryId) ? (
              <LoaderSmall message="Adding Tracker…" />
            ) : (
              <button
                className="button sm-outliner"
                onClick={() => handleAddNewTracker(categoryId)}
              >
                <span>
                  <Octicon icon={Plus} />
                </span>
                <span>Add Tracker</span>
              </button>
            )}
          </div>
        </li>
      )}
    </Draggable>
  );
};

EditableCategory.propTypes = {
  index: PropTypes.number,
  categoryId: PropTypes.string,
  category: PropTypes.object,
  handleConfirmDeleteCategory: PropTypes.func,
  handleChangeCategoryLabel: PropTypes.func,
  trackers: PropTypes.object,
  trackersSort: PropTypes.object,
  usedEmojisData: PropTypes.object,
  handleOnChangeTracker: PropTypes.func,
  handleConfirmDeleteTracker: PropTypes.func,
  handleAddNewTracker: PropTypes.func
};

export default EditableCategory;
