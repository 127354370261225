import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import "./css/SignInForm.css";
import { fireauth } from "../lib/firebase";
import ForgotPassword from "./ForgotPassword";

const SignInForm = () => {
  const [forgotPasswordIsOpen, setForgotPasswordIsOpen] = useState(false);
  const [error, setError] = useState(null);

  const openModal = () => {
    setForgotPasswordIsOpen(true);
  };

  const closeModal = () => {
    setForgotPasswordIsOpen(false);
  };

  const signIn = (email, password) => {
    fireauth.signInWithEmailAndPassword(email, password).catch(error => {
      setError(error.message);
    });
  };

  const clearError = () => {
    setError(null);
  };

  return (
    <div className="SignInForm">
      <ForgotPassword
        modalIsOpen={forgotPasswordIsOpen}
        closeModal={closeModal}
      />
      <Formik
        initialValues={{ email: "", password: "" }}
        validate={values => {
          const errors = {};

          if (!values.email) {
            errors.email = "Required";
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = "Invalid email address";
          }

          if (!values.password) {
            errors.password = "Required";
          }

          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          signIn(values.email, values.password);
          setSubmitting(false);
        }}
      >
        {({ errors, touched, isSubmitting }) => (
          <>
            <Form>
              <ul>
                <li
                  className={errors.email && touched.email ? "field-error" : ""}
                >
                  <label htmlFor="email">Email</label>
                  <Field type="text" name="email" autoComplete="email" />
                </li>
                <li
                  className={
                    errors.password && touched.password ? "field-error" : ""
                  }
                >
                  <label htmlFor="password">Password</label>
                  <Field
                    type="password"
                    name="password"
                    autoComplete="current-password"
                  />
                  <button
                    type="button"
                    className="SignInForm__Forgot"
                    onClick={openModal}
                  >
                    Forgot Password?
                  </button>
                </li>
                <li>
                  <button type="submit" disabled={isSubmitting}>
                    Log In
                  </button>
                </li>
              </ul>
            </Form>
            {error && (
              <div className="SignInForm__Errors">
                <ul>
                  <li>{error}</li>
                </ul>
                <div>
                  <button type="button" onClick={clearError}>
                    Close
                  </button>
                </div>
              </div>
            )}
          </>
        )}
      </Formik>
    </div>
  );
};

export default SignInForm;
