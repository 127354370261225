import React from "react";
import { ReactComponent as SfIcon } from "../../images/starmemory-icon-V2.svg";
import "./css/Loading.css";

const Loading = () => {
  return (
    <div className="Loading">
      <div>
        <SfIcon />
      </div>
      <h1>Preparing to Launch!</h1>
    </div>
  );
};

export default Loading;
