import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as SmIcon } from "../images/starmemory-icon-V2.svg";
import { ReactComponent as SmLogo } from "../images/starmemory-wordmark.svg";
import DataSetSelector from "./DataSetSelector";
import "./css/Header.css";

const Header = ({ userDocRef, selectedDataSet, handleDataSetChange }) => (
  <header className="Header">
    <div>
      <div className="Header__Title">
        <h1>
          <SmIcon />
          <SmLogo />
        </h1>
      </div>
      <div className="Header__Controls">
        <DataSetSelector
          userDocRef={userDocRef}
          selectedDataSet={selectedDataSet}
          handleDataSetChange={handleDataSetChange}
        />
      </div>
    </div>
  </header>
);

Header.propTypes = {
  user: PropTypes.object,
  userDocRef: PropTypes.object,
  selectedDataSet: PropTypes.string,
  handleDataSetChange: PropTypes.func
};

export default Header;
