export const DEFAULT_DATASET = "default";
export const DATE_FORMAT = "YYYY-MM-DD";
export const DATE_FORMAT_SHORT = "YYYY-MM";
export const DATE_FORMAT_DAY = "DD";
export const START_YEAR = 2000;
export const MOBILE_BREAK = 768;

export const DISPLAY_TYPE_TIMELINE = "Timeline";
export const DISPLAY_TYPE_STREAK = "Streak";
export const DISPLAY_TYPE_CHART = "Chart";
export const DISPLAY_TYPES = [
  DISPLAY_TYPE_STREAK,
  DISPLAY_TYPE_TIMELINE,
  DISPLAY_TYPE_CHART
];

export const VIEW_DATA_DISPLAY = "data-display";
export const VIEW_DETAIL_DAY = "detail-day";
export const VIEW_EDIT_TRACKERS = "edit-trackers";
export const VIEW_ACCOUNT_SETTINGS = "account-settings";

// CHART DISPLAY
export const WEEK_THRESH = 30;
export const MONTH_THRESH = 10;
export const LINE_TYPE_ALL = "all";
export const LINE_TYPE_CATEGORIES = "categories";
export const LINE_TYPE_TRACKERS = "trackers";
export const ROLLUP_TYPE_DAY = "day";
export const ROLLUP_TYPE_WEEK = "week";
export const ROLLUP_TYPE_MONTH = "month";

// TIMELINE DISPLAY
export const DISPLAY_DAY_TYPE_EMOJIS = "DayEmojis";
export const DISPLAY_DAY_TYPE_TINYSQUARES = "DayTinySquares";
export const DISPLAY_DAY_TYPE_SQUARES = "DaySquares";
export const DISPLAY_DAY_TYPE_POINTS = "DayPoints";
export const DISPLAY_DAY_TYPE_TOTAL = "DayTotal";

export const NEW_TRACKER_LABEL = "New";
