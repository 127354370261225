import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import Octicon, { Star } from "@primer/octicons-react";

import { DATE_FORMAT } from "../../../lib/constants.js";
import Emoji from "../../Emoji";
import monthEmojis from "../../../lib/monthEmojis";

const StreakSidebarCategory = ({
  trackers,
  trackersSort,
  filteredTrackers
}) => {
  const unfilteredTrackers = trackersSort.filter(
    trackerId => !filteredTrackers.includes(trackerId)
  );

  let trackerRows = [];
  unfilteredTrackers.forEach(trackerId => {
    if (trackers[trackerId]) {
      const tracker = trackers[trackerId];
      trackerRows.push(
        <div key={trackerId} className="Streak__Tracker">
          <span
            className="tracker-emoji"
            style={{
              backgroundColor: tracker.colour
            }}
          >
            <Emoji emoji={tracker.emoji} />
          </span>
          <span>{tracker.label}</span>
        </div>
      );
    }
  });

  return unfilteredTrackers.length > 0 ? (
    <div className="Streak__Category">{trackerRows}</div>
  ) : null;
};

const StreakSidebar = ({
  compact,
  categoriesSort,
  trackers,
  trackersSort,
  filteredTrackers
}) => {
  return (
    <div className="Streak__Sidebar">
      {categoriesSort.map(categoryId => {
        return (
          <StreakSidebarCategory
            key={categoryId}
            trackersSort={trackersSort[categoryId]}
            trackers={trackers}
            filteredTrackers={filteredTrackers}
          />
        );
      })}
      <div className="Streak__ScoreLabel">
        {compact ? <Octicon icon={Star} ariaLabel="Score" /> : "★ Score"}
      </div>
    </div>
  );
};

const StreakDays = ({
  endDate,
  selectedDate,
  onDayClick,
  daysToDisplay,
  categoriesSort,
  trackers,
  trackersSort,
  filteredTrackers,
  entries
}) => {
  let days = [];
  let currentMonth = null;

  for (let i = 1; i <= daysToDisplay; i++) {
    const date = moment(endDate).subtract(daysToDisplay - i, "days");
    let showMonth = false;
    let showEmoji = false;

    if (currentMonth !== date.format("M")) {
      currentMonth = date.format("M");

      if (date.daysInMonth() - date.date() > 1) {
        showMonth = true;
      }

      if (showMonth && endDate.diff(date, "days") < 2) {
        showMonth = false;
        showEmoji = true;
      }
    }

    days.push(
      <StreakDay
        key={date.format(DATE_FORMAT)}
        date={date}
        selected={selectedDate.format(DATE_FORMAT) === date.format(DATE_FORMAT)}
        onDayClick={onDayClick}
        showMonth={showMonth}
        showEmoji={showEmoji}
        categoriesSort={categoriesSort}
        trackers={trackers}
        trackersSort={trackersSort}
        filteredTrackers={filteredTrackers}
        entry={entries[date.format(DATE_FORMAT)]}
      />
    );
  }

  return <div className="Streak__Days">{days}</div>;
};

const StreakDay = ({
  date,
  showMonth,
  showEmoji,
  selected,
  onDayClick,
  categoriesSort,
  trackersSort,
  trackers,
  filteredTrackers,
  entry
}) => {
  const weekday = date.format("dddd");
  const isWeekend = weekday === "Saturday" || weekday === "Sunday";
  const isToday = date.format(DATE_FORMAT) === moment().format(DATE_FORMAT);
  const isMonthStart = 1 === parseInt(date.format("D"), 10);

  let score = 0;
  if (entry && entry.items) {
    score = entry.items.reduce((accumulator, trackerId) => {
      return trackers[trackerId] && !filteredTrackers.includes(trackerId)
        ? accumulator + trackers[trackerId].points
        : accumulator;
    }, 0);
  }

  if (score >= 1000 || score <= -1000) {
    score = Math.sign(score) * (Math.abs(score) / 1000).toFixed(0) + "k";
  }

  return (
    <div
      className={[
        "Streak__Day",
        isToday ? "Streak__Day--today" : "",
        isMonthStart ? "Streak__Day--month-start" : "",
        isWeekend ? "Streak__Day--weekend" : "Streak__Day--weekday",
        `Streak__Day--${weekday}`,
        selected ? "Streak__Day--selected" : ""
      ].join(" ")}
      tabIndex="0"
      onClick={() => onDayClick(date)}
      onKeyPress={e => {
        if (e.key === "Enter") {
          onDayClick(date);
        }
      }}
    >
      <div className="Streak__DayDate">
        {(showMonth || showEmoji) && (
          <div className="Streak__DayMonth">
            <Emoji emoji={monthEmojis[date.format("M")]} />
            {showMonth && date.format("MMMM")}
          </div>
        )}
      </div>
      <div className="Streak__DayCategories">
        <div className="Streak__DayDay">{date.format("D")}</div>
        {categoriesSort.map(categoryId => {
          return (
            <StreakDayCategory
              key={categoryId}
              date={date}
              trackersSort={trackersSort[categoryId]}
              trackers={trackers}
              filteredTrackers={filteredTrackers}
              entry={entry}
            />
          );
        })}
        <div className="Streak__DayTotal" title={`★ ${score}`}>
          {score}
        </div>
      </div>
    </div>
  );
};

const StreakDayCategory = ({
  trackers,
  trackersSort,
  filteredTrackers,
  entry
}) => {
  const unfilteredTrackers = trackersSort.filter(
    trackerId => !filteredTrackers.includes(trackerId)
  );
  let trackerRows = [];
  unfilteredTrackers.forEach(trackerId => {
    if (trackers[trackerId]) {
      let total = 0;

      if (entry && entry.items) {
        total = entry.items.reduce((accumulator, item) => {
          if (item !== trackerId) {
            return accumulator;
          } else {
            return accumulator + 1;
          }
        }, 0);
      }

      trackerRows.push(
        <div key={trackerId} className="Streak__DayTracker">
          {total >= 1 && (
            <span
              className="tracker-emoji"
              style={{ backgroundColor: trackers[trackerId].colour }}
              title={`×${total}`}
            >
              {trackers[trackerId].emoji}
            </span>
          )}
        </div>
      );
    }
  });

  return trackerRows.length > 0 ? (
    <div className="Streak__DayCategory">
      <span />
      {trackerRows}
    </div>
  ) : null;
};

StreakDayCategory.propTypes = {
  category: PropTypes.object,
  trackers: PropTypes.object,
  trackersSort: PropTypes.array,
  filteredTrackers: PropTypes.array,
  entry: PropTypes.object
};

const StreakTable = ({
  compact,
  endDate,
  selectedDate,
  onDayClick,
  daysToDisplay,
  trackers,
  trackersSort,
  filteredTrackers,
  categoriesSort,
  entries
}) => {
  return (
    <div className="Streak__Table">
      <StreakSidebar
        compact={compact}
        categoriesSort={categoriesSort}
        trackers={trackers}
        trackersSort={trackersSort}
        filteredTrackers={filteredTrackers}
      />
      <StreakDays
        endDate={endDate}
        selectedDate={selectedDate}
        onDayClick={onDayClick}
        daysToDisplay={daysToDisplay}
        categoriesSort={categoriesSort}
        trackers={trackers}
        trackersSort={trackersSort}
        filteredTrackers={filteredTrackers}
        entries={entries}
      />
    </div>
  );
};

StreakTable.propTypes = {
  startDate: PropTypes.object,
  daysToDisplay: PropTypes.number,
  trackers: PropTypes.object,
  trackersSort: PropTypes.object,
  filteredTrackers: PropTypes.array,
  categories: PropTypes.object,
  categoriesSort: PropTypes.array,
  compact: PropTypes.bool.isRequired,
  endDate: PropTypes.object,
  selectedDate: PropTypes.object,
  onDayClick: PropTypes.func.isRequired,
  entries: PropTypes.object
};

export default StreakTable;
