import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import {
  LINE_TYPE_ALL,
  LINE_TYPE_CATEGORIES,
  LINE_TYPE_TRACKERS
} from "../../../lib/constants.js";
import DisplayPicker from "../../DisplayPicker";
import DatePicker from "../../DatePicker";

const ChartControls = ({
  startDate,
  endDate,
  setDateRange,
  setLineType,
  lineType,
  displayType,
  handleChangeDisplayType,
  byScore,
  onToggleByScore
}) => {
  const startDateMonthChange = monthIndex => {
    let newDate = moment(startDate).month(monthIndex);
    setDateRange({ startDate: newDate });
  };

  const endDateMonthChange = monthIndex => {
    let newDate = moment(endDate).month(monthIndex);
    setDateRange({ endDate: newDate });
  };

  const startDateYearChange = year => {
    let newDate = moment(startDate).year(year);
    setDateRange({ startDate: newDate });
  };

  const endDateYearChange = year => {
    let newDate = moment(endDate).year(year);
    setDateRange({ endDate: newDate });
  };

  const startDateDayChange = day => {
    let newDate = moment(startDate).date(day);
    setDateRange({ startDate: newDate });
  };

  const endDateDayChange = day => {
    let newDate = moment(endDate).date(day);
    setDateRange({ endDate: newDate });
  };

  return (
    <div className="DisplayControls">
      <div className="DisplayControls__Type" role="group">
        <DisplayPicker
          displayType={displayType}
          handleChangeDisplayType={handleChangeDisplayType}
        />
      </div>
      <div className="DisplayControls__Custom">
        <div role="group" className="Toggle">
          <div
            className={[
              "Toggle__Radio",
              !byScore ? "Toggle__Radio--selected" : ""
            ].join(" ")}
          >
            <label htmlFor="chart-bytotal">Total</label>
            <input
              onChange={() => onToggleByScore(false)}
              id="chart-bytotal"
              type="radio"
              name="byScore"
              value="0"
              checked={!byScore}
            />
          </div>
          <div
            className={[
              "Toggle__Radio",
              byScore ? "Toggle__Radio--selected" : ""
            ].join(" ")}
          >
            <label htmlFor="chart-byscore">Score</label>
            <input
              onChange={() => onToggleByScore(true)}
              id="chart-byscore"
              type="radio"
              name="byScore"
              value="1"
              checked={byScore}
            />
          </div>
        </div>
        <div role="group" className="Toggle">
          <div
            className={[
              "Toggle__Radio",
              lineType === LINE_TYPE_ALL ? "Toggle__Radio--selected" : ""
            ].join(" ")}
          >
            <label htmlFor="lineType-all">All</label>
            <input
              type="radio"
              id="lineType-all"
              name="lineType-all"
              onChange={() => {
                setLineType(LINE_TYPE_ALL);
              }}
              value={LINE_TYPE_ALL}
              checked={lineType === LINE_TYPE_ALL}
            />
          </div>
          <div
            className={[
              "Toggle__Radio",
              lineType === LINE_TYPE_CATEGORIES ? "Toggle__Radio--selected" : ""
            ].join(" ")}
          >
            <label htmlFor="lineType-categories">Categories</label>
            <input
              type="radio"
              id="lineType-categories"
              name="lineType-categories"
              onChange={() => {
                setLineType(LINE_TYPE_CATEGORIES);
              }}
              value={LINE_TYPE_CATEGORIES}
              checked={lineType === LINE_TYPE_CATEGORIES}
            />
          </div>
          <div
            className={[
              "Toggle__Radio",
              lineType === LINE_TYPE_TRACKERS ? "Toggle__Radio--selected" : ""
            ].join(" ")}
          >
            <label htmlFor="lineType-trackers">Trackers</label>
            <input
              type="radio"
              id="lineType-trackers"
              name="lineType-trackers"
              onChange={() => {
                setLineType(LINE_TYPE_TRACKERS);
              }}
              value={LINE_TYPE_TRACKERS}
              checked={lineType === LINE_TYPE_TRACKERS}
            />
          </div>
        </div>
        <DatePicker
          onDayChange={startDateDayChange}
          onMonthChange={startDateMonthChange}
          onYearChange={startDateYearChange}
          date={startDate}
          label="Start"
        />
        <DatePicker
          onDayChange={endDateDayChange}
          onMonthChange={endDateMonthChange}
          onYearChange={endDateYearChange}
          date={endDate}
          label="End"
        />
      </div>
    </div>
  );
};

ChartControls.propTypes = {
  startDate: PropTypes.object.isRequired,
  endDate: PropTypes.object.isRequired,
  setDateRange: PropTypes.func.isRequired,
  setLineType: PropTypes.func.isRequired,
  lineType: PropTypes.string.isRequired,
  displayType: PropTypes.string.isRequired,
  handleChangeDisplayType: PropTypes.func.isRequired,
  byScore: PropTypes.bool.isRequired,
  onToggleByScore: PropTypes.func.isRequired
};

export default ChartControls;
